import Stack from '@mui/material/Stack';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { SupplyChainPhaseListTable } from './SupplyChainPhaseListTable';
import { SupplyChainPhaseDropdown } from './SupplyChainPhaseDropdown';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';
import StockSettings from '../../../../domain/automator/stock/StockSettings';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useUpdateStockSettings } from '../../../../api/automator/stock/useUpdateStockSettings';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { Box } from '@mui/system';

interface SupplyChainPhaseSettingsProps {
  type: SupplyChainPhaseType;
  supplyChainPhases: SupplyChainPhase[];
  stockSettings: StockSettings;
}

export const SupplyChainPhaseSettings = ({
  type,
  supplyChainPhases,
  stockSettings,
}: SupplyChainPhaseSettingsProps) => {
  const [defaultPhaseId, setDefaultPhaseId] = useState<number>(
    type == SupplyChainPhaseType.MANUFACTURING
      ? stockSettings.defaultManufacturingSupplyChainPhaseId
      : stockSettings.defaultTransportSupplyChainPhaseId
  );

  const { mutate: updateStockSettings } = useUpdateStockSettings(stockSettings.id);

  const openDialog = useOpenDialog(DialogId.UPDATE_PRODUCT_GROUP_SUPPLY_CHAIN_PHASE);

  const { t } = useTranslation();

  const onUpdateDefault = (supplyChainPhaseId: number) => {
    setDefaultPhaseId(supplyChainPhaseId);

    updateStockSettings(
      {
        default_manufacturing_supply_chain_phase_id:
          type == SupplyChainPhaseType.MANUFACTURING ? supplyChainPhaseId : null,
        default_transport_supply_chain_phase_id:
          type == SupplyChainPhaseType.TRANSPORT ? supplyChainPhaseId : null,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.settings.stock_settings_updated));
        },
      }
    );
  };

  const phases = supplyChainPhases!.filter((phase) => phase.type === type);

  return (
    <Stack
      direction="column"
      alignItems="left"
      gap={2}
    >
      <SupplyChainPhaseListTable
        type={type}
        supplyChainPhases={phases}
      />

      <Stack
        gap={1}
        padding={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Typography>
            {type == SupplyChainPhaseType.TRANSPORT
              ? t(
                  tokens.automator.stock.settings.supply_chain_phases
                    .default_transport_supply_chain_phase
                )
              : t(
                  tokens.automator.stock.settings.supply_chain_phases
                    .default_manufacturing_supply_chain_phase
                )}
            :
          </Typography>

          <SupplyChainPhaseDropdown
            type={type}
            selected={defaultPhaseId}
            onSelect={onUpdateDefault}
          />
        </Stack>

        <Box>
          <ActionButton
            onClick={() => openDialog({ type })}
            variant="outlined"
            label={
              type == SupplyChainPhaseType.TRANSPORT
                ? t(
                    tokens.automator.stock.settings
                      .update_transport_product_group_supply_chain_phase
                  )
                : t(
                    tokens.automator.stock.settings
                      .update_manufacturing_product_group_supply_chain_phase
                  )
            }
          />
        </Box>
      </Stack>
    </Stack>
  );
};
