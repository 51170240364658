import { PICK_SESSION_SETTINGS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import PickSessionSettings from '../../../domain/automator/pickSessions/PickSessionSettings';
import { PickSessionSettingsQueryKey } from '../../queryKeys/PickSessionSettingsQueryKey';

export const useFetchPickSessionSettings = () => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_SETTINGS_PATH);

  return useFetchData<PickSessionSettings>(url, PickSessionSettingsQueryKey());
};
