import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import Shipment from '../../../domain/automator/shipments/Shipment';
import { OrderShipmentQueryKey } from '../../queryKeys/OrderShipmentQueryKey';

export const useFetchOrderShipments = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/shipments');
  return useFetchData<Shipment[]>(url, OrderShipmentQueryKey(orderId));
};
