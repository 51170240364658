import { CancelOrderItemDialog } from '../pages/automator/orders/components/CancelOrderItemDialog';
import { AddRetailerDialog } from '../pages/automator/retailers/components/AddRetailerDialog';
import { ConfirmCreatePickSessionDialog } from '../pages/automator/retailers/components/ConfirmCreatePickSessionDialog';
import { useAuth } from '../store/auth/useAuth';
import { FirstRetailerCreatedTutorialDialog } from './tutorials/FirstRetailerCreatedTutorialDialog';
import { HandleReturnItemDialog } from '../pages/automator/returns/dialogs/HandleReturnItemDialog';
import { CreateMessageTemplateDialog } from '../pages/automator/resolutions/dialogs/CreateMessageTemplateDialog';
import { CreateReturnDialog } from '../pages/automator/returns/dialogs/CreateReturnDialog';
import { SendEmailThreadReplyDialog } from '../pages/automator/resolutions/dialogs/SendEmailThreadReplyDialog';
import { UpdateOrderNoteDialog } from '../pages/automator/resolutions/dialogs/UpdateOrderNoteDialog';
import { UpdateOfferInternalReferenceDialog } from '../pages/automator/orders/dialogs/UpdateOfferInternalReferenceDialog';
import { CreateResendDialog } from '../pages/automator/resolutions/dialogs/CreateResendDialog';
import { useAccountIdFromUrl } from '../api/useAccountIdFromUrl';
import { CreateProductPurchaseCostsDialog } from '../pages/pd/pages/performances/dialogs/CreateProductPurchaseCostsDialog';
import { CreateProductReorderIntervalDialog } from '../pages/pd/pages/performances/dialogs/CreateProductReorderIntervalDialog';
import { UpdateProductReorderIntervalDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductReorderIntervalDialog';
import { CreateOfferShippingCostsDialog } from '../pages/pd/pages/performances/dialogs/CreateOfferShippingCostsDialog';
import { UpdateOfferShippingCostsDialog } from '../pages/pd/pages/performances/dialogs/UpdateOfferShippingCostsDialog';
import { UpdateProductPurchaseCostsDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductPurchaseCostsDialog';
import { UpdateOfferPerformanceOverwriteDialog } from '../pages/pd/pages/performances/dialogs/UpdateOfferPerformanceOverwriteDialog';
import { CreateOfferPerformanceOverwriteDialog } from '../pages/pd/pages/performances/dialogs/CreateOfferPerformanceOverwriteDialog';
import { UpdateProductPurchaseVolumeDialog } from '../pages/pd/pages/performances/dialogs/UpdateProductPurchaseVolumeDialog';
import { CreateProductPurchaseVolumeDialog } from '../pages/pd/pages/performances/dialogs/CreateProductPurchaseVolumeDialog';
import { CreateManualSeasonalEventDialog } from '../pages/pd/pages/performances/dialogs/CreateManualSeasonalEventDialog';
import { CreateManualPerformanceEventDialog } from '../pages/pd/pages/performances/dialogs/CreateManualPerformanceEventDialog';
import { CreateAutoEmailEventRuleDialog } from '../pages/automator/autoEmail/dialogs/CreateAutoEmailEventRuleDialog';
import { UpdateMessageTemplateDialog } from '../pages/automator/resolutions/dialogs/UpdateMessageTemplateDialog';
import { UpdateAutoEmailEventRuleDialog } from '../pages/automator/autoEmail/dialogs/UpdateAutoEmailEventRuleDialog';
import { OrderSummaryDialog } from '../pages/automator/orderSummaries/dialogs/OrderSummaryDialog';
import { CreateEmailThreadDialog } from '../pages/automator/resolutions/dialogs/CreateEmailThreadDialog';
import { ViewReturnScanPhotosDialog } from '../pages/automator/returns/dialogs/ViewReturnScanPhotosDialog';
import { UpdateOffersDialog } from '../pages/automator/orders/dialogs/UpdateOffersDialog';
import { UpdateRetailerReturnAddressDialog } from '../pages/automator/retailers/dialogs/UpdateRetailerReturnAddressDialog';
import { CreateProductGroupDialog } from '../pages/automator/products/dialogs/CreateProductGroupDialog';
import { UpdateProductsDialog } from '../pages/automator/products/dialogs/UpdateProductsDialog';
import { WarningDialog } from '../pages/automator/shared/WarningDialog';
import { InfoDialog } from '../pages/automator/shared/InfoDialog';
import { UpdateUpdatedBillingDetailsDialog } from '../pages/automator/customerInvoiceRequests/dialogs/UpdatedUpdatedBillingDetailsDialog';
import { SelectDifferentVatPercentageProductsDialog } from '../pages/automator/customerInvoiceRequests/dialogs/SelectDifferentVatPercentageProductsDialog';
import { CreateRestockDialog } from '../pages/automator/stock/dialogs/CreateRestockDialog';
import { CreateSupplierDialog } from '../pages/automator/stock/dialogs/CreateSupplierDialog';
import { StocktakeDialog } from '../pages/automator/stock/dialogs/StocktakeDialog';
import { UpdateInventoryStockDialog } from '../pages/automator/stock/dialogs/UpdateInventoryStockDialog';
import { RemoveSalesVariantFromInventoryItemDialog } from '../pages/automator/stock/dialogs/RemoveSalesVariantFromInventoryItemDialog';
import { UpdateInventoryItemOfferListingsDialog } from '../pages/automator/stock/dialogs/UpdateInventoryItemOfferListingsDialog';
import { CreateSupplyChainPhaseDialog } from '../pages/automator/stock/dialogs/CreateSupplyChainPhaseDialog';
import { EditRestockDialog } from '../pages/automator/stock/dialogs/EditRestockDialog';
import { UpdateSupplyChainPhaseDialog } from '../pages/automator/stock/dialogs/UpdateSupplyChainPhaseDialog';
import { UpdateProductGroupSupplyChainPhaseDialog } from '../pages/automator/stock/dialogs/UpdateProductGroupSupplyChainPhaseDialog';
import { RestockSupplierOptionDialog } from '../pages/automator/stock/dialogs/RestockSupplierOptionDialog';
import { UpdateRestockItemDialog } from '../pages/automator/stock/dialogs/UpdateRestockItemDialog';
import { DeleteRestockItemDialog } from '../pages/automator/stock/dialogs/DeleteRestockItemDialog';
import { UpdateInventoryItemLeadTimeDialog } from '../pages/automator/stock/dialogs/UpdateInventoryItemLeadTimeDialog';

export const Dialogs = () => {
  const { isAuthenticated } = useAuth();
  const accountId = useAccountIdFromUrl();

  if (!isAuthenticated || !accountId) {
    return null;
  }

  return (
    <>
      {/* Automator */}
      <WarningDialog />
      <InfoDialog />

      <UpdateInventoryStockDialog />
      <StocktakeDialog />
      <CancelOrderItemDialog />
      <AddRetailerDialog />
      <ConfirmCreatePickSessionDialog />
      <FirstRetailerCreatedTutorialDialog />
      <HandleReturnItemDialog />
      <CreateMessageTemplateDialog />
      <CreateReturnDialog />
      <SendEmailThreadReplyDialog />
      <UpdateOrderNoteDialog />
      <UpdateOfferInternalReferenceDialog />
      <CreateResendDialog />
      <CreateAutoEmailEventRuleDialog />
      <UpdateMessageTemplateDialog />
      <UpdateAutoEmailEventRuleDialog />
      <OrderSummaryDialog />
      <CreateEmailThreadDialog />
      <ViewReturnScanPhotosDialog />
      <UpdateOffersDialog />
      <UpdateRetailerReturnAddressDialog />
      <CreateProductGroupDialog />
      <UpdateProductsDialog />
      <UpdateRestockItemDialog />

      <CreateRestockDialog />
      <RestockSupplierOptionDialog />

      <EditRestockDialog />
      <CreateSupplierDialog />
      <RemoveSalesVariantFromInventoryItemDialog />

      <UpdateUpdatedBillingDetailsDialog />

      <SelectDifferentVatPercentageProductsDialog />

      <UpdateInventoryItemOfferListingsDialog />

      <CreateSupplyChainPhaseDialog />
      <UpdateSupplyChainPhaseDialog />
      <UpdateProductGroupSupplyChainPhaseDialog />

      <DeleteRestockItemDialog />
      <UpdateInventoryItemLeadTimeDialog />

      {/* PD */}
      <CreateProductPurchaseCostsDialog />
      <CreateProductReorderIntervalDialog />
      <CreateOfferShippingCostsDialog />
      <UpdateOfferShippingCostsDialog />
      <UpdateProductPurchaseCostsDialog />
      <UpdateProductReorderIntervalDialog />
      <UpdateOfferPerformanceOverwriteDialog />
      <CreateOfferPerformanceOverwriteDialog />
      <UpdateProductPurchaseVolumeDialog />
      <CreateProductPurchaseVolumeDialog />

      <CreateManualSeasonalEventDialog />
      <CreateManualPerformanceEventDialog />
    </>
  );
};
