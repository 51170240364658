import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DepletionPeriodChip } from './DepletionPeriodChip';

interface ProductStockSummaryDepletionPeriodTableRowProps {
  amount: number;
  depletionDays?: number;
  onClick?: (depletionDays?: number) => void;
}

export const ProductStockSummaryDepletionPeriodTableRow = ({
  amount,
  depletionDays,
  onClick,
}: ProductStockSummaryDepletionPeriodTableRowProps) => {
  return (
    <TableRow onClick={onClick ? () => onClick(depletionDays) : undefined}>
      <TableCell>
        <DepletionPeriodChip
          leadTime={{
            manufacturingSupplyChainPhaseId: null,
            transportSupplyChainPhaseId: null,
            manufacturingDurationInDays: 30,
            transportDurationInDays: 30,
          }}
          daysTillDepletion={depletionDays}
          showDays={false}
        />
      </TableCell>

      <TableCell>
        <Typography>{amount} PRODUCTS</Typography>
      </TableCell>
    </TableRow>
  );
};
