export const enum EndpointQueryKey {
  PROPOSED_ACTIONS = 'proposed-actions',
  USER = 'user',
  ACCOUNT = 'account',
  RETAILERS = 'retailers',
  RETAILER_SETTINGS = 'retailer_settings',
  ADMIN_ACCOUNT = 'admin_account',
  STAMP_CODES_REPORT = 'stamp-codes-report',
  SHIPMENTS = 'shipments',
  SHIPMENTS_REPORT = 'shipments_reports',
  ORDERS = 'orders',
  ORDER = 'order',
  PINNED_ORDERS = 'pinned_orders',
  ORDER_SHIPMENT = 'order_shipment',
  ORDER_RETURNS = 'order_returns',
  ORDER_EVENTS = 'order_events',
  ORDER_MESSAGE_THREADS = 'order_message_threads',
  ORDER_RESOLUTION_CASES = 'order_resolution_cases',
  RESOLUTION_DOSSIERS = 'resolution_dossiers',

  ORDER_SUMMARY = 'order_summary',
  PICK_SESSIONS = 'pick_sessions',
  PICK_SESSIONS_REPORT = 'pick_sessions_report',
  PICK_SESSION_SETTINGS = 'pick_session_settings',
  REDUCED_PICK_SESSION = 'reduced_pick_session',
  CUSTOMER_INVOICE_SETTINGS = 'customer_invoice_settings',

  MESSAGE_TEMPLATE = 'message_template',
  EMAIL_RULES = 'email_rules',
  EVENT_RULES = 'event_rules',
  EMAIL_THREAD = 'email_thread',
  THREAD_MESSAGE = 'email_message',
  RESOLUTION_CASE = 'resolution_case',
  RESEND = 'resend',
  RETURNS = 'returns',
  SUPPLIERS = 'suppliers',
  RESTOCKS = 'restocks',
  RECOMMENDED_RESTOCK_REPORTS = 'recommended_restock_reports',
  RESTOCK = 'restock',
  STOCK_SETTINGS = 'stock_settings',

  INVENTORY_STOCKS = 'product_restock_items',
  INVENTORY_ITEM_RESTOCK_ITEMS = 'inventory_item_restock_items',

  INVENTORY_ITEM_STOCK_ADJUSTMENTS = 'product_stock_adjustments',
  INVENTORY_ITEMS = 'inventory_items',
  REDUCED_INVENTORY_ITEMS = 'reduced_inventory_items',
  PRODUCT_STOCK_INSIGHT = 'product_stock_insight',

  INVENTORY_ITEM_SUPPLIERS = 'product_suppliers',
  OFFERS = 'offer',
  CANCELLATION_BLOCKS = 'cancellation_blocks',
  CUSTOMER_INVOICE_REQUESTS = 'customer_invoice_requests',
  CUSTOMER_INVOICES_BUNDLES = 'customer_invoices_bundles',
  ORDER_CUSTOMER_INVOICES = 'order_customer_invoices',

  RETURN_ITEM = 'return_item',
  RETURNS_BY_INVENTORY_ITEM_ID = 'return_items_by_inventory_item_id',
  RETURN_SCANS = 'return_scans',
  RETURN_SCAN_DOCUMENTS = 'return_scan_documents',

  REIMBURSEMENTS = 'reimbursements',
  REIMBURSEMENT_REQUESTS = 'reimbursement_requests',

  CUSTOMER_ORDER = 'customer_order',
  ORDER_NOTE = 'order_note',

  STORE = 'store',
  OFFER_PERFORMANCES = 'offer_performances',
  PRODUCT_RANKS_SEARCH_TERMS = 'product_ranks_search_terms',
  PRODUCT_RANKS_CATEGORIES = 'product_ranks_categories',
  PRODUCT_RANKS = 'product_ranks',
  SEARCH_TERM_VOLUMES = 'search_term_volumes',
  ASSORTMENT_IMPRESSIONS = 'assortment_impressions',
  ASSORTMENT_VISITS = 'assortment_visits',
  CAMPAIGNS = 'campaigns',
  PICK_SESSION_PICK_ORDERS = 'pick_session_pick_orders',
  AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS = 'ad_group_search_term_performance_search_terms',
  AD_GROUP_SEARCH_TERM_PERFORMANCES = 'ad_group_search_term_performances',
  AD_GROUP_PERFORMANCES = 'ad_group_performances',
  AD_PERFORMANCES = 'ad_performances',
  PRODUCTS = 'products',
  PRODUCT_GROUPS = 'product_groups',
  PRODUCT_NOTE = 'product_note',
  INVENTORY_ITEM_BY_PRODUCT_ID = 'inventory_item_by_product_id',
  SHARE_OF_VOICE_AVERAGES = 'share_of_voices_search_terms',
  TIMELINE_EVENTS = 'timeline_events',
  TIMELINE_NOTES = 'timeline_notes',
  MANUAL_SEASONAL_EVENTS = 'manual_seasonal_events',

  PRODUCT_PURCHASE_COSTS = 'product_purchase_costs',
  PRODUCT_REORDER_INTERVAL = 'product_reorder_interval',
  OFFER_SHIPPING_COSTS = 'offer_shipping_costs',
  PRODUCT_PURCHASE_VOLUME = 'product_purchase_volume',
  SHARE_OF_VOICE_TOTALS = 'share_of_voices_totals',
  SHARE_OF_VOICES = 'share_of_voices',

  PRODUCT_PERFORMANCES = 'product_performances',

  PRODUCT_POSITIONS = 'product_positions',

  CONTESTED_CATEGORIES = 'contested_categories',
}
