import Typography from '@mui/material/Typography';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface StockAmountProps {
  amount: number;
}

export const StockAmount = ({ amount }: StockAmountProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tokens.automator.stock.product_stock_summaries_table.quantity_tooltip)}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Inventory2OutlinedIcon />
        <Typography>{amount}</Typography>
      </Stack>
    </Tooltip>
  );
};
