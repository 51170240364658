import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import PickSessions from '../../../domain/automator/pickSessions/PickSessions';
import { PickSessionsQueryKey } from '../../queryKeys/PickSessionsQueryKey';
import { useFetchData } from '../../useFetchData';

export const useFetchPickSessions = (date?: string) => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + (date ? `?date=${date}` : ''));
  return useFetchData<PickSessions>(url, PickSessionsQueryKey(date), {
    refetchInterval: (latestData) => {
      if (!latestData) {
        return 1000;
      }
      return latestData.shouldPoll ? 2500 : false;
    },
  });
};
