import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import PickOrders from '../../../domain/automator/pickSessions/PickOrders';
import { PickSessionPickOrdersQueryKey } from '../../queryKeys/PickSessionPickOrdersQueryKey';
import { useFetchData } from '../../useFetchData';

export const useFetchPickSessionPickOrders = (pickSessionId: number) => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + `/${pickSessionId}/pick-orders`);

  return useFetchData<PickOrders>(url, PickSessionPickOrdersQueryKey(pickSessionId), {
    refetchInterval: (latestData) => {
      if (!latestData) {
        return 1000;
      }
      return latestData.shouldPoll ? 2500 : false;
    },
  });
};
