export const AUTOMATOR_API_URL = import.meta.env.VITE_AUTOMATOR_API_URL;

export const ACCOUNTS_PATH = 'accounts';
export const ORDERS_PATH = 'orders';

export const EVENT_RULES_PATH = 'event-rules';
export const EVENT_RULE_ACTIONS_PATH = 'event-rule-actions';
export const PROPOSED_ACTIONS_PATH = 'proposed-actions';

export const MESSAGE_TEMPLATES_PATH = 'message-templates';
export const EMAIL_THREADS_PATH = 'email-threads';
export const MESSAGE_THREADS_PATH = 'message-threads';

export const RESOLUTION_DOSSIERS_PATH = 'resolution-dossiers';
export const RESOLUTION_CASES_PATH = 'resolution-cases';

export const ORDER_NOTES_PATH = 'order-notes';

export const OFFERS_PATH = 'offers';
export const CANCELLATION_BLOCKS_PATH = 'cancellation-blocks';

export const CUSTOMER_INVOICE_REQUESTS_PATH = 'customer-invoice-requests';
export const CUSTOMER_INVOICES_PATH = 'customer-invoices';
export const CUSTOMER_INVOICE_LINES_PATH = 'customer-invoice-lines';
export const CUSTOMER_INVOICE_BUNDLES_PATH = 'customer-invoice-bundles';
export const CUSTOMER_INVOICE_SETTINGS_PATH = 'customer-invoice-settings';

export const RESENDS_PATH = 'resends';

export const CUSTOMER_ORDERS_PATH = '/customers/orders';
export const CUSTOMER_CUSTOMER_INVOICES_PATH = '/customers/invoices';

export const RETURN_PROCESSING_RESULTS_PATH = 'return-processing-results';
export const RETURN_ITEMS_PATH = 'return-items';
export const RETURNS_PATH = 'returns';
export const RETURN_SCANS_PATH = 'return-scans';
export const SUPPLIERS_PATH = 'suppliers';
export const INVENTORY_ITEMS = 'inventory-items';
export const INVENTORY_ITEM_NOTES = 'inventory-item-notes';
export const RESTOCK_ITEMS = 'restock-items';

export const RESTOCKS_PATH = 'restocks';
export const SALES_VARIANTS_PATH = 'sales-variants';
export const INVENTORY_STOCKS_PATH = 'inventory-stocks';
export const SUPPLY_CHAIN_PHASES = 'supply-chain-phases';
export const RECOMMENDED_RESTOCK_REPORTS_PATH = 'recommended-restock-reports';
export const STOCK_SETTINGS = 'stock-settings';

export const ORDER_ITEMS_PATH = 'order-items';
export const PICK_SESSION_PATH = 'pick-sessions';
export const PICK_SESSION_SETTINGS_PATH = 'pick-session-settings';

export const PICK_ORDERS_PATH = 'pick-orders';

export const LABEL_TYPE_RULES_PATH = 'label-type-rules';
export const RETAILERS_PATH = 'retailers';
export const RETAILER_SETTINGS_PATH = 'customer-invoice-retailer-settings';

export const SHIPMENTS_PATH = 'shipments';

export const SETTINGS_PATH = 'settings';

export const RETURN_REASON_REPORTS_PATH = 'return-reports/reasons';

export const PRODUCTS_PATH = 'products';
export const PRODUCT_GROUPS_PATH = 'product-groups';
export const PRODUCT_NOTES_PATH = 'product-notes';

export const REIMBURSEMENTS_PATH = 'reimbursements';
export const REIMBURSEMENT_REQUESTS_PATH = 'reimbursement-requests';
