import Stack from '@mui/material/Stack';

import * as React from 'react';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { InventoryItemInsightTable } from './InventoryItemInsightTable';
import Typography from '@mui/material/Typography';
import { useFetchInventoryItemInsight } from '../../../../api/automator/stock/useFetchInventoryItemInsight';
import { InventoryItemInsightCalculator } from './InventoryItemInsightCalculator';

interface InventoryItemInsightViewProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemInsightView = ({ inventoryItem }: InventoryItemInsightViewProps) => {
  const { data: inventoryItemInsight, isLoading } = useFetchInventoryItemInsight(inventoryItem.id);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Stack
      direction="row"
      gap={2}
      paddingBottom={2}
    >
      <InventoryItemInsightTable inventoryItem={inventoryItem} />

      <InventoryItemInsightCalculator
        inventoryItem={inventoryItem}
        inventoryItemInsight={inventoryItemInsight!}
      />
    </Stack>
  );
};
