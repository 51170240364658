import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from '../layouts/automator/Layout';
import RetailersPage from '../pages/automator/retailers/RetailersPage';
import PricingTablePage from '../pages/automator/pricing/PricingTablePage';
import OrdersPage from '../pages/automator/orders/OrdersPage';
import PickSessionsPage from '../pages/automator/pick-sessions/PickSessionsPage';
import ProductsPage from '../pages/automator/products/ProductsPage';
import ShipmentsPage from '../pages/automator/shipments/ShipmentsPage';
import SettingsPage from '../pages/automator/settings/SettingsPage';
import ReturnsPage from '../pages/automator/returns/ReturnsPage';
import ResolutionDossiersPage from '../pages/automator/resolutions/ResolutionDossiersPage';
import ResendsPage from '../pages/automator/resolutions/ResendsPage';
import { DistributionParty } from '../domain/automator/orders/DistributionParty';
import ReportsPage from '../pages/automator/reports/ReportsPage';
import ReturnScansPage from '../pages/automator/returnScans/ReturnScansPage';
import AutoMailPage from '../pages/automator/autoEmail/AutoMailPage';
import AlertsPage from '../pages/automator/alerts/AlertsPage';
import ReimbursementsPage from '../pages/automator/reimbursements/ReimbursementsPage';
import PinnedOrdersPage from '../pages/automator/pinned-orders/PinnedOrdersPage';
import ScrollPreserver from './ScrollPreserver';
import CustomerInvoicesPage from '../pages/automator/customerInvoiceRequests/CustomerInvoicesPage';
import StockPage from '../pages/automator/stock/StockPage';
import AccountCreatedFormPage from '../pages/automator/forms/AccountCreatedFormPage';

export const automatorRoutes: RouteObject[] = [
  {
    path: '/automator/accounts/:accountId',
    element: (
      <Layout>
        <ScrollPreserver />
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        path: 'account-created-form',
        element: <AccountCreatedFormPage />,
      },
      {
        path: 'vvb-orders',
        element: <OrdersPage distributionParty={DistributionParty.BOL} />,
      },
      {
        path: 'non-vvb-orders',
        element: <OrdersPage distributionParty={DistributionParty.RETAILER} />,
      },
      {
        path: 'pick-sessions',
        element: <PickSessionsPage />,
      },
      {
        path: 'pick-sessions/:pickSessionId',
        element: <PickSessionsPage />,
      },
      {
        path: 'reimbursements',
        element: <ReimbursementsPage />,
      },
      {
        path: 'pinned-orders',
        element: <PinnedOrdersPage />,
      },
      {
        path: 'products',
        element: <ProductsPage />,
      },
      {
        path: 'retailers',
        element: <RetailersPage />,
      },
      {
        path: 'shipments',
        element: <ShipmentsPage />,
      },

      {
        path: 'stock',
        element: <StockPage />,
      },

      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'pricing',
        element: <PricingTablePage />,
      },
      {
        path: 'returns',
        element: <ReturnsPage />,
      },
      {
        path: 'return-scans',
        element: <ReturnScansPage />,
      },
      {
        path: 'resolution-dossiers',
        element: <ResolutionDossiersPage />,
      },
      {
        path: 'resolution-dossiers/:dossierId/cases/:caseId',
        element: <ResolutionDossiersPage />,
      },
      {
        path: 'resends',
        element: <ResendsPage />,
      },
      {
        path: 'auto-mail',
        element: <AutoMailPage />,
      },
      {
        path: 'alerts',
        element: <AlertsPage />,
      },
      {
        path: 'reports',
        element: <ReportsPage />,
      },
      {
        path: 'customer-invoice-requests',
        element: <CustomerInvoicesPage />,
      },
    ],
  },
];
