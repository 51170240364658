import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { UpdateInventoryItemLeadTimeForm } from './UpdateInventoryItemLeadTimeForm';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import InventoryItemInsight from '../../../../domain/automator/stock/InventoryItemInsight';

export const UpdateInventoryItemLeadTimeDialog = () => {
  const id = DialogId.UPDATE_INVENTORY_ITEM_LEAD_TIME;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.stock.update_lead_time_dialog.update_lead_time)}
        >
          <UpdateInventoryItemLeadTimeForm
            inventoryItemInsight={data.inventoryItemInsight}
            days={data.days}
            type={data.type}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateInventoryItemLeadTimeDialogData {
  inventoryItemInsight: InventoryItemInsight;
  days: number;
  type: SupplyChainPhaseType;
}
