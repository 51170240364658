import { useState } from 'react';
import ThreadMessage from '../../../../domain/automator/messages/ThreadMessage'; // @ts-ignore
import DOMPurify from 'dompurify';
import parse from 'react-html-parser';
import { tss } from 'tss-react/mui-compat';
import { getTimeStringFromDate } from '../../../../helpers/getTimeStringFromDate';
import { colors } from '@mui/material';
import SenderTypeChip from './SenderTypeChip';
import { SenderType } from '../../../../domain/automator/messages/SenderType';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/system';
import { getShortenedAlphabeticFormatFromDate } from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface ThreadMessageViewProps {
  message: ThreadMessage;
}

const removeOldMessages = (body: string): string => {
  const patterns = [
    '-----Original Message-----',
    '---- Original Message ----',
    '----- Forwarded Message-----',
    '-------- Oorspronkelijk bericht --------',
    '--- Original Message ----',
    '<span style="font-size:11.0pt;font-family:" calibri,sans-serif>Van:</span>',
    '<b>From:</b>',
    'Op \\w{2} \\d{1,2} \\w{3} \\d{4} \\d{1,2}:\\d{2} schreef',
    'On\\s\\w{3},\\s\\w{3}\\s\\d{1,2},\\s\\d{4},\\s\\d{1,2}:\\d{2}(\u202F|\\s)?[AP]M',
  ];

  let cutIndex = body.length;
  for (const pattern of patterns) {
    const regex = new RegExp(pattern, 'i');
    const index = body.search(regex);
    if (index !== -1 && index < cutIndex) {
      cutIndex = index;
    }
  }
  return body.substring(0, cutIndex);
};

export const ThreadMessageView = ({ message }: ThreadMessageViewProps) => {
  const { classes, cx } = useStyles();
  const [showAll, setShowAll] = useState(false);

  const determineMailBackgroundColor = (senderType: SenderType) => {
    switch (senderType) {
      case SenderType.SELLER:
        return colors.green[50];
      case SenderType.CUSTOMER:
        return colors.cyan[50];
      case SenderType.BOL:
        return colors.blue[50];
      case SenderType.TRANSPORTER:
        return colors.lightBlue[50];
      default:
        return colors.grey[50];
    }
  };

  const date = new Date(message.sentDateTime || message.createdDateTime);
  const cleanedBody = removeOldMessages(message.body);
  const displayedBody = showAll ? message.body : cleanedBody;

  return (
    <Box width="100%">
      <Badge
        badgeContent={
          <Box onClick={() => setShowAll(!showAll)}>
            <SenderTypeChip senderType={message.senderType} />
          </Box>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ width: '100%' }}
      >
        <div
          style={{ width: '100%' }}
          className={cx(classes.emailMessage)}
        >
          {parse(
            DOMPurify.sanitize(`
                <div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.0; border-radius: 25px; color: #333; padding: 35px 35px 15px 35px; width: 100%; margin: 0 0; background-color: ${determineMailBackgroundColor(
                  message.senderType
                )};">
                  <div>${displayedBody}</div>
                  <div style="width: 100%; text-align: right; padding-top: 10px; font-size: 11px">
                    ${
                      getShortenedAlphabeticFormatFromDate(date) + ' ' + getTimeStringFromDate(date)
                    }
                  </div>
                </div>
              `)
          )}
        </div>
      </Badge>
    </Box>
  );
};

const useStyles = tss.create(() => ({
  emailMessage: {
    '& p': {
      lineHeight: '1.3',
      margin: '0 0',
    },
    '& br': {
      lineHeight: '1',
    },
  },
}));
