import { useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import { Collapse, TableCell } from '@mui/material';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionDossierInfoTableRow } from './ResolutionDossierInfoTableRow';
import { ResolutionCaseView } from './ResolutionCaseView';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { useParams } from 'react-router';
import useQueryParam from '../../../../helpers/useQueryParam';

interface ResolutionDossierTableRowProps {
  resolutionDossier: ResolutionDossier;
  isFirst: boolean;
  isLast: boolean;
  selectedStatus: ResolutionDossierStatus;
}

export const ResolutionDossierTableRow = ({
  resolutionDossier,
  isFirst,
  isLast,
  selectedStatus,
}: ResolutionDossierTableRowProps) => {
  const path = usePaths();
  const navigate = useNavigate();
  const { dossierId, caseId } = useParams();
  const scroll = useQueryParam('scroll');
  const isSelected = resolutionDossier.id === parseInt(dossierId!);

  const onSelectCaseId = (resolutionCaseId: number | null) => {
    if (resolutionCaseId === (caseId ? parseInt(caseId) : null) || !resolutionCaseId) {
      navigate(`${path.automator.resolution_dossiers}`);
    } else {
      navigate(
        `${path.automator.resolution_dossiers}/${resolutionDossier.id}/cases/${resolutionCaseId}?status=${resolutionDossier.status}&scroll=false`
      );
    }
  };

  useEffect(() => {
    if (isSelected && scroll != 'false') {
      setTimeout(() => {
        const element = document.getElementById(`resolution-dossier-${resolutionDossier.id}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1200);
    }
  }, [isSelected, scroll, resolutionDossier.id]);

  return (
    <>
      <ResolutionDossierInfoTableRow
        resolutionDossier={resolutionDossier}
        setSelectedCaseId={onSelectCaseId}
        selectedCaseId={caseId ? parseInt(caseId) : null}
        isSelected={isSelected}
        isFirst={isFirst}
        isLast={isLast}
        selectedStatus={selectedStatus}
      />
      <div id={`resolution-dossier-${resolutionDossier.id}`} />

      {isSelected && (
        <TableRow>
          <TableCell
            style={{ padding: 0 }}
            colSpan={10}
          >
            <Collapse
              in={isSelected}
              unmountOnExit
            >
              <ResolutionCaseView
                resolutionCaseId={parseInt(caseId!)}
                hasOpenResend={resolutionDossier.hasOpenResend}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {isSelected && (
        <TableRow style={{ backgroundColor: '#F8F9FA' }}>
          <TableCell colSpan={10}></TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ResolutionDossierTableRow;
