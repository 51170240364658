import { tokens } from '../../../../locales/translationTokens';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface EmailVariableSelectionProps {
  onSelectVariable: (variable: string) => void;
}

export const EmailVariableSelection = ({ onSelectVariable }: EmailVariableSelectionProps) => {
  const { t } = useTranslation();

  const variablesMap = new Map<string, string>([
    [tokens.automator.resolutions.email_variables.customer_name, '[[CUSTOMER_NAME]]'],
    [tokens.automator.resolutions.email_variables.transporter_name, '[[TRANSPORTER_NAME]]'],
    [tokens.automator.resolutions.email_variables.store_name, '[[STORE_NAME]]'],
    [tokens.automator.resolutions.email_variables.bol_order_id, '[[BOL_ORDER_ID]]'],
    [tokens.automator.resolutions.email_variables.bol_retailer_id, '[[BOL_RETAILER_ID]]'],
    [
      tokens.automator.resolutions.email_variables.shipment_tracking_link,
      '[[SHIPMENT_TRACKING_LINK]]',
    ],
    [
      tokens.automator.resolutions.email_variables.order_expected_delivery_date,
      '[[ORDER_EXPECTED_DELIVERY_DATE]]',
    ],
    [tokens.automator.resolutions.email_variables.bol_case_number, '[[BOL_CASE_NUMBER]]'],
  ]);

  return (
    <Stack gap={1}>
      <Typography
        paddingBottom={1}
        variant="h6"
      >
        {t(tokens.automator.resolutions.email_variables.email_variables)}
      </Typography>
      {Array.from(variablesMap).map(([token, variable]) => (
        <Typography
          style={{ cursor: 'pointer' }}
          key={variable}
          onClick={() => onSelectVariable(variable)}
        >
          {t(token)}
        </Typography>
      ))}
    </Stack>
  );
};
