import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useCallback, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { SortDirection } from '../../../../api/page/SortDirection';
import { TableHeaderSort } from '../../../../api/page/TableHeaderSort';
import { StockValue } from './StockValue';
import ProductsPageSearchBar from '../../products/components/ProductsPageSearchBar';
import Stack from '@mui/material/Stack';
import { ProductGroupChip } from '../../products/components/ProductGroupChip';
import { debounce } from '@mui/material';
import { useFetchProductGroups } from '../../../../api/automator/products/useFetchProductGroups';
import { StockAverageSalesToggleButtons } from './StockAverageSalesToggleButton';
import {
  InventoryItemsSort,
  useFetchInventoryItems,
} from '../../../../api/automator/stock/useFetchInventoryItems';
import { InventoryItemTableRow } from './InventoryItemTableRow';
import { useGlobalInventoryItemDaysAverage } from '../../../../store/inventoryItemDaysAverage/useGlobalInventoryItemDaysAverage';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ActionIcon } from '../../orders/components/ActionIcon';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';

export const InventoryItemListTable = () => {
  const PAGE_SIZE = 50;
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(InventoryItemsSort.AVERAGE_SALES_PER_DAY_30D);
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [showDetailsInventoryItemId, setShowDetailsInventoryItemId] = useState<number | null>(null);
  const [productGroupId, setProductGroupId] = useState<number | undefined>(undefined);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const openCreateProductGroupDialog = useOpenDialog(DialogId.CREATE_PRODUCT_GROUP);

  const { globalDays, updateGlobalDays } = useGlobalInventoryItemDaysAverage();

  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();
  const { data: paginatedInventoryItems, isLoading } = useFetchInventoryItems(
    page,
    pageSize,
    sort,
    sortDirection,
    productGroupId,
    query
  );

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      setQuery(searchQuery);
    }, 500),
    []
  );

  if (isLoading || isLoadingProductGroups) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const inventoryItems = paginatedInventoryItems!.content;

  const onUpdateShowDetailsInventoryItemId = (inventoryItemId: number) => {
    if (inventoryItemId === showDetailsInventoryItemId) {
      setShowDetailsInventoryItemId(null);
    } else {
      setShowDetailsInventoryItemId(inventoryItemId);
    }
  };

  const onSelectProductGroupId = (selectedProductGroupId: number) => {
    if (productGroupId === selectedProductGroupId) {
      setProductGroupId(undefined);
    } else {
      setProductGroupId(selectedProductGroupId);
    }
  };

  const onSelectSort = (selectedSort: InventoryItemsSort, direction: SortDirection) => {
    setSort(selectedSort);
    setSortDirection(direction);
  };

  const renderTableHeaderSort = (title: string, sortOfHeader: InventoryItemsSort) => {
    return (
      <TableHeaderSort
        title={t(title)}
        direction={sortOfHeader === sort ? sortDirection : undefined}
        sort={sortOfHeader}
        onSelect={(chosenSort, chosenDirection) => onSelectSort(chosenSort, chosenDirection)}
        isSelected={sort === sortOfHeader}
      />
    );
  };

  const handleChangeGlobalDays = (days: 7 | 14 | 30) => {
    updateGlobalDays(days);
  };

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <ProductsPageSearchBar
                initialQuery={query}
                isDisabled={isLoading}
                onSearch={(searchQuery) => debouncedSearch(searchQuery)}
              />
            </TableCell>
            <TableCell colSpan={2}>
              <Stack
                direction="row"
                gap={0.5}
                alignItems="center"
              >
                {productGroups?.map((productGroup) => (
                  <ProductGroupChip
                    key={productGroup.id}
                    productGroup={productGroup}
                    onClick={() => onSelectProductGroupId(productGroup.id)}
                    isSelected={productGroupId === productGroup.id}
                  />
                ))}

                <ActionIcon
                  tooltip={t(tokens.automator.products.dialogs.create_group.create_group)}
                  onClick={() => {
                    openCreateProductGroupDialog();
                  }}
                  icon={<PlusIcon />}
                />
              </Stack>
            </TableCell>
            <TableCell colSpan={1}>
              <StockValue valueInCents={inventoryItems.totalValueInCents} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.product,
                InventoryItemsSort.TITLE
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.quantity,
                InventoryItemsSort.CURRENT_STOCK
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.depletion_period,
                InventoryItemsSort.DAYS_TILL_DEPLETION_30D
              )}
            </TableCell>

            <TableCell>
              {t(tokens.automator.stock.product_stock_summaries_table.restock_signal)}
            </TableCell>

            <TableCell>
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
              >
                <StockAverageSalesToggleButtons
                  selectedDays={globalDays}
                  onChange={(days) => handleChangeGlobalDays(days)}
                  averageSalesPerDay7d={7}
                  averageSalesPerDay14d={14}
                  averageSalesPerDay30d={30}
                />
                {renderTableHeaderSort(
                  tokens.automator.stock.product_stock_summaries_table
                    .average_sales_per_day_7d_14d_30d,
                  InventoryItemsSort.AVERAGE_SALES_PER_DAY_30D
                )}
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventoryItems!.inventoryItems.map((item) => (
            <InventoryItemTableRow
              key={item.id}
              showDetails={showDetailsInventoryItemId === item.id}
              setShowDetailsInventoryItemId={onUpdateShowDetailsInventoryItemId}
              inventoryItem={item}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={paginatedInventoryItems!.pagination.totalElements}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
        page={page}
        rowsPerPage={pageSize}
      />
    </Scrollbar>
  );
};
