import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import ActionButton from '../../../../components/ActionButton';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { useUpdateInventoryItemLeadTime } from '../../../../api/automator/stock/useUpdateInventoryItemLeadTime';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InventoryItemInsight from '../../../../domain/automator/stock/InventoryItemInsight';

interface UpdateInventoryItemLeadTimeFormProps {
  onClick?: () => void;
  inventoryItemInsight: InventoryItemInsight;
  days: number;
  type: SupplyChainPhaseType;
}

export const UpdateInventoryItemLeadTimeForm = ({
  inventoryItemInsight,
  days: initialDays,
  type,
  onClick,
}: UpdateInventoryItemLeadTimeFormProps) => {
  const { mutate: updateInventoryItemLeadTime } = useUpdateInventoryItemLeadTime(
    inventoryItemInsight.inventoryItemId
  );

  const [days, setDays] = useState(initialDays);

  const { t } = useTranslation();

  const doUpdateLeadTime = () => {
    updateInventoryItemLeadTime(
      {
        manufacturing_supply_chain_phase_id:
          type == SupplyChainPhaseType.MANUFACTURING
            ? null
            : inventoryItemInsight.leadTime.manufacturingSupplyChainPhaseId,
        manufacturing_duration_in_days:
          type == SupplyChainPhaseType.MANUFACTURING
            ? days
            : inventoryItemInsight.leadTime.manufacturingDurationInDays,
        transport_supply_chain_phase_id:
          type == SupplyChainPhaseType.TRANSPORT
            ? null
            : inventoryItemInsight.leadTime.transportSupplyChainPhaseId,
        transport_duration_in_days:
          type == SupplyChainPhaseType.TRANSPORT
            ? days
            : inventoryItemInsight.leadTime.transportDurationInDays,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.update_lead_time_dialog.lead_time_updated));
          onClick?.();
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <TextField
        type="number"
        label={t(tokens.automator.stock.settings.supply_chain_phases.days)}
        value={days}
        onChange={(e) => setDays(Number(e.target.value))}
      />

      <ActionButton
        color="primary"
        label={t(tokens.automator.stock.update_lead_time_dialog.update_lead_time)}
        onClick={doUpdateLeadTime}
      />
    </Stack>
  );
};
