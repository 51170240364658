import Typography from '@mui/material/Typography';
import { truncateString } from '../../../../helpers/truncateString';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { ProductThumbnail } from './ProductThumbnail';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import { OrderItemStatus } from '../../../../domain/automator/orders/OrderItemStatus';
import ReducedOffer from '../../../../domain/automator/offers/ReducedOffer';
import { ProductPageIcon } from '../ProductPageIcon';

interface OrderItemProductInfoProps {
  orderStatus: OrderStatus;
  orderItemStatus: OrderItemStatus;
  quantity: number;
  offer: ReducedOffer;
}

export const OrderItemProductInfo = ({
  orderItemStatus,
  orderStatus,
  quantity,
  offer,
}: OrderItemProductInfoProps) => {
  const openDialog = useOpenDialog(DialogId.UPDATE_OFFER_INTERNAL_REFERENCE);

  const { t } = useTranslation();

  return (
    <Stack>
      <Stack
        direction="column"
        gap={1}
      >
        {orderItemStatus == OrderItemStatus.CANCELLED && orderStatus != OrderStatus.CANCELLED && (
          <Typography
            width={20}
            variant="h6"
          >
            {t(tokens.automator.orders.order_item_status.cancelled)}
          </Typography>
        )}
      </Stack>

      <Stack
        direction="row"
        gap={0.7}
        justifyContent="space-between"
      >
        <ProductThumbnail
          height={60}
          thumbnailUrl={offer.product.thumbnailUrl}
          quantity={quantity}
        />

        <Stack
          direction="column"
          justifyContent="center"
          gap={0.2}
        >
          <Typography
            noWrap
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            variant="body1"
          >
            {truncateString(offer.product.title, 33)}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            gap={0.7}
          >
            <Typography variant="body2">{offer.product.ean}</Typography>
            <Typography variant="body2"> | </Typography>
            <Typography
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() =>
                openDialog({
                  productId: offer.product.id,
                  internalReference: offer.internalReference ? offer.internalReference : '',
                })
              }
            >
              {offer.internalReference
                ? offer.internalReference
                : t(
                    tokens.automator.orders.dialogs.update_internal_reference.add_internal_reference
                  )}
            </Typography>
            {offer.product.productPageUrl && (
              <ProductPageIcon productPageUrl={offer.product.productPageUrl} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
