import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Return from '../../../../domain/automator/returns/Return';
import { ReturnItemCard } from './ReturnItemCard';
import { ReturnProcessingResultListTable } from './ReturnProcessingResultListTable';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';

interface HandledReturnTableRowProps {
  return_: Return;
}

export const HandledReturnTableRow = ({ return_ }: HandledReturnTableRowProps) => {
  const returnItems = return_.returnItems.filter(
    (returnItem) => returnItem.returnProcessingResults.length > 0
  );

  return (
    <>
      {returnItems.map((returnItem) => {
        const quantityHandled = returnItem.returnProcessingResults.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        return (
          <TableRow key={returnItem.id}>
            <TableCell>
              <ReturnItemCard
                key={returnItem.id}
                quantity={quantityHandled}
                returnItem={returnItem}
                return_={return_}
                showTransporterInfo={true}
              />
            </TableCell>
            <TableCell align="right">
              <Table>
                <TableBody>
                  <ReturnProcessingResultListTable returnItem={returnItem} />
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
