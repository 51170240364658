import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogId } from './DialogId';
import { DialogIdDataTypeMap } from './DialogIdDataMap';

interface DialogState {
  isOpen: boolean;
  data?: DialogIdDataTypeMap[DialogId];
}

type DialogStates = Record<DialogId, DialogState>;

const initialState: DialogStates = {
  [DialogId.WARNING]: { isOpen: false },
  [DialogId.INFO]: { isOpen: false },

  [DialogId.REMOVE_SALES_VARIANT_FROM_INVENTORY_ITEM]: { isOpen: false },
  [DialogId.UPDATE_INVENTORY_STOCK]: { isOpen: false },
  [DialogId.CANCEL_ORDER_ITEM]: { isOpen: false },
  [DialogId.ADD_RETAILER]: { isOpen: false },
  [DialogId.CONFIRM_CREATE_PICK_SESSION]: { isOpen: false },
  [DialogId.FIRST_RETAILER_CREATED]: { isOpen: false },
  [DialogId.CREATE_RETURN]: { isOpen: false },
  [DialogId.HANDLE_RETURN_ITEM]: { isOpen: false },
  [DialogId.CREATE_MESSAGE_TEMPLATE]: { isOpen: false },
  [DialogId.SEND_MESSAGE_THREAD_REPLY]: { isOpen: false },
  [DialogId.UPDATE_ORDER_NOTE_DIALOG]: { isOpen: false },
  [DialogId.UPDATE_OFFER_INTERNAL_REFERENCE]: { isOpen: false },
  [DialogId.CREATE_RESEND]: { isOpen: false },
  [DialogId.STOCKTAKE]: { isOpen: false },

  [DialogId.CREATE_OFFER_SHIPPING_COSTS]: { isOpen: false },
  [DialogId.CREATE_PRODUCT_PURCHASE_COSTS]: { isOpen: false },
  [DialogId.CREATE_PRODUCT_REORDER_INTERVAL]: { isOpen: false },
  [DialogId.UPDATE_PRODUCT_REORDER_INTERVAL]: { isOpen: false },
  [DialogId.UPDATE_PRODUCT_PURCHASE_COSTS]: { isOpen: false },
  [DialogId.UPDATE_OFFER_SHIPPING_COSTS]: { isOpen: false },

  [DialogId.CREATE_OFFER_PERFORMANCE_OVERWRITE]: { isOpen: false },
  [DialogId.UPDATE_OFFER_PERFORMANCE_OVERWRITE]: { isOpen: false },
  [DialogId.CREATE_RESTOCK]: { isOpen: false },
  [DialogId.EDIT_RESTOCK]: { isOpen: false },
  [DialogId.CREATE_SUPPLIER]: { isOpen: false },

  [DialogId.CREATE_EMAIL_THREAD]: { isOpen: false },

  [DialogId.UPDATE_MESSAGE_TEMPLATE]: { isOpen: false },

  [DialogId.CREATE_EMAIL_EVENT_RULE]: { isOpen: false },
  [DialogId.UPDATE_AUTO_MAIL_EVENT_RULE]: { isOpen: false },

  [DialogId.ORDER_SUMMARY]: { isOpen: false },
  [DialogId.VIEW_RETURN_SCAN_PHOTOS]: { isOpen: false },
  [DialogId.UPDATE_OFFERS]: { isOpen: false },
  [DialogId.UPDATE_RETAILER_RETURN_ADDRESS]: { isOpen: false },
  [DialogId.CREATE_PRODUCT_GROUP]: { isOpen: false },
  [DialogId.UPDATE_PRODUCTS]: { isOpen: false },
  [DialogId.UPDATE_UPDATED_BILLING_DETAILS]: { isOpen: false },

  [DialogId.SETUP_VAT_PERCENTAGE_PRODUCTS]: { isOpen: false },

  [DialogId.UPDATE_INVENTORY_ITEM_OFFER_LISTINGS]: { isOpen: false },

  [DialogId.CREATE_SUPPLY_CHAIN_PHASE]: { isOpen: false },
  [DialogId.UPDATE_SUPPLY_CHAIN_PHASE]: { isOpen: false },
  [DialogId.UPDATE_PRODUCT_GROUP_SUPPLY_CHAIN_PHASE]: { isOpen: false },
  [DialogId.RESTOCK_SUPPLIER_OPTION]: { isOpen: false },
  [DialogId.UPDATE_RESTOCK_ITEM]: { isOpen: false },
  [DialogId.DELETE_RESTOCK_ITEM]: { isOpen: false },
  [DialogId.UPDATE_INVENTORY_ITEM_LEAD_TIME]: { isOpen: false },

  //pd

  [DialogId.CREATE_PRODUCT_PURCHASE_VOLUME]: { isOpen: false },
  [DialogId.UPDATE_PRODUCT_PURCHASE_VOLUME]: { isOpen: false },

  [DialogId.CREATE_MANUAL_PERFORMANCE_EVENT]: { isOpen: false },
  [DialogId.CREATE_MANUAL_SEASONAL_EVENT]: { isOpen: false },
};

export const dialogSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openDialog: <T extends DialogId>(
      state: DialogStates,
      action: PayloadAction<{ id: T; data: DialogIdDataTypeMap[T] }>
    ) => {
      const { id, data } = action.payload;
      state[id] = { isOpen: true, data };
    },
    closeDialog: (state, action: PayloadAction<DialogId>) => {
      const id = action.payload;
      state[id].isOpen = false;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const dialogReducer = dialogSlice.reducer;
