import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import HandlingResultChip from './HandlingResultChip';
import ProcessingResultChip from './ProcessingResultChip';
import Typography from '@mui/material/Typography';
import StillAcceptReturnProcessingResultButton from './StillAcceptReturnProcessingResultButton';

interface ReturnProcessingResultListTableProps {
  returnItem: ReturnItem;
}

export const ReturnProcessingResultListTable = ({
  returnItem,
}: ReturnProcessingResultListTableProps) => {
  const returnProcessingResults = returnItem.returnProcessingResults;

  return returnProcessingResults.map((returnProcessingResult, index) => (
    <TableRow key={index}>
      <TableCell>
        <Typography variant="subtitle1">{returnProcessingResult.quantity}x</Typography>
      </TableCell>
      <TableCell>
        <ProcessingResultChip processingResult={returnProcessingResult.processingResult} />
      </TableCell>
      <TableCell>
        <HandlingResultChip handlingResult={returnProcessingResult.handlingResult} />
      </TableCell>
      <TableCell width={200}>
        <Box>{returnProcessingResult.processingDateTime}</Box>
      </TableCell>
      <TableCell>
        <StillAcceptReturnProcessingResultButton returnProcessingResult={returnProcessingResult} />
      </TableCell>
    </TableRow>
  ));
};
