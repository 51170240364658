import { INVENTORY_STOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { RecommendedRestockReportsQueryKey } from '../../queryKeys/RecommendedRestockReportsQueryKey';

export const useUpdateInventoryStock = (inventoryItemId: number, inventoryStockId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_STOCKS_PATH + '/' + inventoryStockId);

  return useAuthorizedPost<undefined, UpdateProductStockData>(url, [
    InventoryItemsQueryKey(),
    InventoryStocksQueryKey(inventoryItemId),
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    RecommendedRestockReportsQueryKey(),
    InventoryItemInsightQueryKey(),
  ]);
};

export interface UpdateProductStockData {
  supplier_id: number | null;
  quantity: number;
  unit_price_in_cents: number | null;
  unit_transport_cost_in_cents: number | null;
  total_cubic_meters: number | null;
  total_weight_in_kg: number | null;
}
