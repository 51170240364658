import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import PickSession from '../../../domain/automator/pickSessions/PickSession';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PickSessionsQueryKey } from '../../queryKeys/PickSessionsQueryKey';
import { PickSessionsReportQueryKey } from '../../queryKeys/PickSessionsReportQueryKey';

export const useRetryFailedPickOrders = (pickSessionId: number) => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + `/${pickSessionId}/retry-failed`);
  return useAuthorizedPost<PickSession, undefined>(url, [
    PickSessionsQueryKey(),
    PickSessionsReportQueryKey(),
    [EndpointQueryKey.ACCOUNT],
  ]);
};
