import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { PICK_SESSION_SETTINGS_PATH } from '../automatorApiPaths';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import PickSessionSettings from '../../../domain/automator/pickSessions/PickSessionSettings';
import { StampBoxType } from '../../../domain/automator/pickSessions/StampBoxType';
import { PickSessionSettingsQueryKey } from '../../queryKeys/PickSessionSettingsQueryKey';

export const useUpdatePickSessionSettings = (settingsId: number) => {
  const url = useAutomatorApiAccountUrl(`${PICK_SESSION_SETTINGS_PATH}/${settingsId}`);
  return useAuthorizedPost<PickSessionSettings, UpdatePickSessionSettingsData>(url, [
    PickSessionSettingsQueryKey(),
  ]);
};

export interface UpdatePickSessionSettingsData {
  stamp_box_type: StampBoxType;
}
