import { tokens } from '../../../locales/translationTokens';
import { useCallback } from 'react';
import AccountPage from '../../../components/AccountPage';
import { Widget } from '@typeform/embed-react';
import { useTranslation } from 'react-i18next';
import { generatePaths } from '../../../routes/usePaths';
import { useAccountIdFromUrl } from '../../../api/useAccountIdFromUrl';

const AccountCreatedFormPage = () => {
  const accountId = useAccountIdFromUrl();

  const paths = generatePaths(accountId as unknown as number);

  const handleOnSubmit = useCallback(() => {
    window.location.href = paths.automator.retailers;
  }, []);

  const { t } = useTranslation();

  return (
    <AccountPage title={t(tokens.automator.account_created_form.title)}>
      <Widget
        id={'VchL71iA'}
        style={{ width: '100%', height: '600px' }}
        onSubmit={handleOnSubmit}
        hideFooter
        hideHeaders
        hidden={{
          account_id: accountId,
        }}
      />
    </AccountPage>
  );
};

export default AccountCreatedFormPage;
