import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PickSession from '../../../../domain/automator/pickSessions/PickSession';
import { PickSessionProgressView } from './PickSessionProgressView';
import { PickSessionDownloads } from './PickSessionDownloads';
import LabelGenerationChip from './LabelGenerationChip';
import TransporterChip from '../../shipments/components/TransporterChip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LabelGeneration } from '../../../../domain/automator/pickSessions/LabelGeneration';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { TransporterCode } from '../../../../domain/automator/shipments/TransporterCode';
import { forwardRef } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useRetryFailedPickOrders } from '../../../../api/automator/pickSessions/useRetryFailedPickOrders';
import { useTranslation } from 'react-i18next';

interface PickSessionTableRowProps {
  pickSession: PickSession;
  onClick: (pickSessionId: number) => void;
}

export const PickSessionTableRow = forwardRef<HTMLTableRowElement, PickSessionTableRowProps>(
  ({ pickSession, onClick }, ref) => {
    const hasPostNl = pickSession.transporterCodes.some(
      (transporterCode) =>
        transporterCode === TransporterCode.TNT_BRIEF || transporterCode === TransporterCode.TNT
    );

    const transporterCodes = pickSession.transporterCodes.filter((transporterCode) => {
      return transporterCode != TransporterCode.TNT_BRIEF && transporterCode != TransporterCode.TNT;
    });

    const { mutate: retryFailedPickOrders, isLoading } = useRetryFailedPickOrders(pickSession.id);

    if (hasPostNl) {
      transporterCodes.push(TransporterCode.TNT);
    }

    const { t } = useTranslation();

    return (
      <TableRow
        hover
        ref={ref}
        onClick={() => onClick(pickSession.id)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell align="left">
          <LabelGenerationChip labelGeneration={pickSession.labelGeneration} />
        </TableCell>

        <TableCell align="left">
          <Stack
            direction="row"
            gap={2}
          >
            <Typography
              width={155}
              variant="body2"
            >
              {pickSession.createdDateTime}
            </Typography>

            {transporterCodes.map((transporterCode) => (
              <TransporterChip
                key={transporterCode}
                transporterCode={transporterCode}
                variant="filled"
                distributionParty={
                  pickSession.labelGeneration == LabelGeneration.VVB
                    ? DistributionParty.BOL
                    : DistributionParty.RETAILER
                }
                withColor
              />
            ))}
          </Stack>
        </TableCell>

        <TableCell align="left">
          <PickSessionProgressView progress={pickSession.progress} />
        </TableCell>

        <TableCell align="right">
          {pickSession.canRetry && (
            <ActionButton
              label={t(tokens.automator.pick_sessions.retry_failed_pick_orders)}
              onClick={() => retryFailedPickOrders(undefined, undefined)}
              isLoading={isLoading}
              variant={'outlined'}
              size="small"
            />
          )}
        </TableCell>

        <TableCell align="right">
          <PickSessionDownloads pickSession={pickSession} />
        </TableCell>
      </TableRow>
    );
  }
);

PickSessionTableRow.displayName = 'PickSessionTableRow';
