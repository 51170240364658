import Stack from '@mui/material/Stack';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { SalesVariantsListTable } from './SalesVariantsListTable';

interface InventoryItemSettingsViewProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemSettingsView = ({ inventoryItem }: InventoryItemSettingsViewProps) => {
  return (
    <Stack
      direction="column"
      gap={2}
      padding={2.5}
    >
      {/*<InventoryItemSupplyChainPhaseSettings inventoryItem={inventoryItem} />*/}

      <SalesVariantsListTable inventoryItem={inventoryItem} />
    </Stack>
  );
};
