enum EventTrigger {
  NEW_ORDER = 'NEW_ORDER',
  ORDER_IS_PLACED_DOUBLE = 'ORDER_IS_PLACED_DOUBLE',
  CUSTOMER_IS_RECURRING = 'CUSTOMER_IS_RECURRING',
  ORDER_OVERDUE = 'ORDER_OVERDUE',
  SHIPMENT_SHIPPED = 'SHIPMENT_SHIPPED',
  SHIPMENT_TRACK_AND_TRACE_UPLOADED = 'SHIPMENT_TRACK_AND_TRACE_UPLOADED',
  SHIPMENT_DELIVERED = 'SHIPMENT_DELIVERED',
  SHIPMENT_DELIVERED_AT_PICKUP_POINT = 'SHIPMENT_DELIVERED_AT_PICKUP_POINT',
  SHIPMENT_RETURNED_TO_SENDER = 'SHIPMENT_RETURNED_TO_SENDER',
  SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER = 'SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER',
  SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT = 'SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT',
  EXPECTED_DELIVERY_DATE_REACHED = 'EXPECTED_DELIVERY_DATE_REACHED',
  PICKUP_POINT_WILL_RETURN_TO_SENDER = 'PICKUP_POINT_WILL_RETURN_TO_SENDER',
  DEBIT_CUSTOMER_INVOICE_CREATED = 'DEBIT_CUSTOMER_INVOICE_CREATED',
  SHIPMENT_OVERDUE = 'SHIPMENT_OVERDUE',
  RETURN_REGISTERED = 'RETURN_REGISTERED',
  RECOVERABLE_RETURN_ITEM_REGISTERED = 'RECOVERABLE_RETURN_ITEM_REGISTERED',
  NOT_RECOVERABLE_RETURN_ITEM_REGISTERED = 'NOT_RECOVERABLE_RETURN_ITEM_REGISTERED',
  RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED = 'RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED',
  RETURN_HANDLED = 'RETURN_HANDLED',
  RETURN_ITEM_REJECTED = 'RETURN_ITEM_REJECTED',
  RETURN_ITEM_EXPIRED = 'RETURN_ITEM_EXPIRED',
  SURCHARGE_CREATED = 'SURCHARGE_CREATED',
}

export default EventTrigger;
