import { ResolutionDossierTableRow } from './ResolutionDossierTableRow';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { useFetchResolutionDossiers } from '../../../../api/automator/resolutions/useFetchResolutionDossiers';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import TableRow from '@mui/material/TableRow';
import { TableCell } from '@mui/material';
import Typography from '@mui/material/Typography';

interface ResolutionDossierListTableRowsProps {
  date: string;
  selectedStatus: ResolutionDossierStatus;
}

export const ResolutionDossierListTableRows = ({
  date,
  selectedStatus,
}: ResolutionDossierListTableRowsProps) => {
  const { data: resolutionDossiers, isLoading } = useFetchResolutionDossiers(selectedStatus, date);

  if (isLoading || !resolutionDossiers) {
    return (
      <TableRow>
        <TableCell
          style={{ padding: 0 }}
          colSpan={10}
        >
          <Typography
            variant="body1"
            align="center"
          >
            Loading...
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  if (resolutionDossiers.resolutionDossiers.length === 0) {
    return <></>;
  }

  const sortedResolutionDossiers = resolutionDossiers.resolutionDossiers.sort((a, b) => {
    const getLatestDate = (dossier: ResolutionDossier): Date => {
      if (dossier.status == ResolutionDossierStatus.HANDLED && dossier.updatedDateTime) {
        return new Date(dossier.updatedDateTime);
      }

      return dossier.resolutionCases.reduce((latestDate, resolutionCase) => {
        const caseLatestReplyDate = resolutionCase?.latestReplyDate
          ? new Date(resolutionCase.latestReplyDate)
          : new Date(0); // Default low-earliest date

        const caseCreatedDate = resolutionCase?.createdDate
          ? new Date(resolutionCase.createdDate)
          : new Date(0); // Default low-earliest date

        const caseMaxDate =
          caseLatestReplyDate > caseCreatedDate ? caseLatestReplyDate : caseCreatedDate;

        return caseMaxDate > latestDate ? caseMaxDate : latestDate;
      }, new Date(0)); // Default low-earliest date in reduce
    };

    return getLatestDate(b).getTime() - getLatestDate(a).getTime();
  });

  return sortedResolutionDossiers.map((resolutionDossier, index) => (
    <ResolutionDossierTableRow
      key={resolutionDossier.id}
      isFirst={index === 0}
      isLast={index === sortedResolutionDossiers.length - 1}
      resolutionDossier={resolutionDossier}
      selectedStatus={selectedStatus}
    />
  ));
};
