import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ProductTableRow from './ProductTableRow';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import { useEffect, useState } from 'react';

interface ProductListTableProps {
  products?: AutomatorProduct[];
  isLoading: boolean;
}

export const ProductListTable = ({ products, isLoading }: ProductListTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    setPage(0);
  }, [products]);

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (products!.length === 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedProducts = products!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.products.product)}</TableCell>
              <TableCell>{t(tokens.automator.products.label_type)}</TableCell>
              <TableCell>{t(tokens.automator.products.more_than)}</TableCell>
              <TableCell>{t(tokens.automator.products.label_notes)}</TableCell>
              <TableCell>{t(tokens.automator.products.groups)}</TableCell>
              <TableCell>{t(tokens.automator.products.vat_percentage)}</TableCell>
              <TableCell>{t(tokens.automator.products.offers)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProducts.map((product) => (
              <ProductTableRow
                key={product.id}
                product={product}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={products!.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
