import { useTranslation } from 'react-i18next';
import { useFetchInventoryItemInsight } from '../../../../api/automator/stock/useFetchInventoryItemInsight';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { tokens } from '../../../../locales/translationTokens';
import TableBody from '@mui/material/TableBody';
import { ProductStockInsightTableRow } from './ProductStockInsightTableRow';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { StockAdjustmentDirection } from '../../../../domain/automator/stock/StockAdjustmentDirection';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { InventoryItemInsightItemType } from '../../../../domain/automator/stock/InventoryItemInsight';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import * as React from 'react';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';

interface InventoryItemInsightTableProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemInsightTable = ({ inventoryItem }: InventoryItemInsightTableProps) => {
  const { data: inventoryItemInsight, isLoading } = useFetchInventoryItemInsight(inventoryItem.id);

  const { t } = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const currentItem = inventoryItemInsight!.items.find(
    (item) => item.type == InventoryItemInsightItemType.CURRENT
  )!;

  const restockItems = inventoryItemInsight!.items.filter(
    (item) => item.type == InventoryItemInsightItemType.RESTOCK
  );

  const returnsItem = inventoryItemInsight!.items.find(
    (item) => item.type == InventoryItemInsightItemType.RETURNS
  )!;

  const salesItem = inventoryItemInsight!.items.find(
    (item) => item.type == InventoryItemInsightItemType.SALES
  )!;

  const startItem = inventoryItemInsight!.items.find(
    (item) => item.type == InventoryItemInsightItemType.PERIOD_START
  )!;

  return (
    <Table
      style={{ marginTop: -20 }}
      size="small"
    >
      <TableBody>
        <ProductStockInsightTableRow
          type={'CURRENT'}
          date={currentItem.date!}
          direction={StockAdjustmentDirection.INCREASE}
          quantity={currentItem.quantity}
          inventoryItem={inventoryItem}
        />

        {restockItems.map((item, index) => (
          <ProductStockInsightTableRow
            key={index}
            type={StockAdjustmentType.RESTOCK}
            date={item.date!}
            quantity={item.quantity}
            direction={StockAdjustmentDirection.INCREASE}
          />
        ))}

        <ProductStockInsightTableRow
          type={StockAdjustmentType.RETURN}
          quantity={returnsItem!.quantity}
          direction={StockAdjustmentDirection.INCREASE}
        />

        <ProductStockInsightTableRow
          type={StockAdjustmentType.SALES}
          quantity={salesItem!.quantity}
          direction={StockAdjustmentDirection.DECREASE}
        />

        <ProductStockInsightTableRow
          type={'PREVIOUS'}
          date={startItem!.date!}
          direction={StockAdjustmentDirection.INCREASE}
          quantity={startItem.quantity}
        />

        <TableRow>
          <SlimTableCell
            paddingVertical={1}
            hasBorderTop={true}
            hasBorderBottom={false}
          >
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.stock_value)}
            </Typography>
          </SlimTableCell>

          <SlimTableCell
            paddingVertical={1}
            hasBorderBottom={false}
            hasBorderTop={true}
          >
            <Box />
          </SlimTableCell>

          <SlimTableCell
            paddingVertical={1}
            hasBorderBottom={false}
            hasBorderTop={true}
          >
            <Typography variant="body2">
              {convertCentsToEuros(inventoryItem.totalValueInCents, true)}
            </Typography>
          </SlimTableCell>

          <SlimTableCell
            paddingVertical={1}
            hasBorderBottom={false}
            hasBorderTop={true}
          >
            <Box />
          </SlimTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
