import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { EventRuleInfoButton } from '../../alerts/components/EventRuleInfoButton';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface AutoMailTriggerGroupTableRowProps {
  trigger: EventTrigger;
  amountOfProducts: number;
  amountOfActiveEventRules: number;
  onClick: () => void;
  isSelected: boolean;
}

export const AutoMailTriggerGroupTableRow = ({
  trigger,
  amountOfProducts,
  amountOfActiveEventRules,
  onClick,
  isSelected,
}: AutoMailTriggerGroupTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow
      onClick={onClick}
      key={trigger}
      hover
      style={{ cursor: 'pointer', backgroundColor: isSelected ? '#f5f5f5' : 'white' }}
    >
      <TableCell>
        <EventRuleInfoButton
          showInfo={false}
          eventTrigger={trigger}
          distributionParty={DistributionParty.MIXED}
        />
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={2}
        >
          <Chip
            style={{ borderRadius: 4 }}
            size="small"
            label={amountOfActiveEventRules + ' ' + t(tokens.automator.auto_mail.campaigns)}
            variant="outlined"
          />

          <Chip
            style={{ borderRadius: 4 }}
            size="small"
            label={amountOfProducts + ' ' + t(tokens.automator.auto_mail.products)}
            variant={'outlined'}
          />
        </Stack>
      </TableCell>

      <TableCell />

      <TableCell />

      <TableCell />

      <TableCell />
    </TableRow>
  );
};
