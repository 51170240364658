import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useApiAdminUrl } from '../../useApiAdminUrl';

export const useUploadStampCodes = () => {
  const url = useApiAdminUrl('stamp-codes');
  return useAuthorizedPost<undefined, UploadStampCodesData>(url);
};

export interface UploadStampCodesData {
  file: string;
}
