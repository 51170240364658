import Stack from '@mui/material/Stack';

import * as React from 'react';
import { useState } from 'react';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../../locales/translationTokens';
import { useFetchSupplyChainPhases } from '../../../../api/automator/stock/useFetchSupplyChainPhases';
import { useTranslation } from 'react-i18next';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { StockAverageSalesToggleButtons } from './StockAverageSalesToggleButton';
import { StockPeriodDropdown, StockPeriodOption } from './StockPeriodDropdown';
import InventoryItemInsight from '../../../../domain/automator/stock/InventoryItemInsight';
import { toast } from 'react-hot-toast';
import { useUpdateInventoryItemLeadTime } from '../../../../api/automator/stock/useUpdateInventoryItemLeadTime';
import { getShortenedAlphabeticFormatFromDate } from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useInventoryItemDaysAverage } from '../../../../store/inventoryItemDaysAverage/useInventoryItemDaysAverage';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import ToggleButton from '@mui/lab/ToggleButton';

interface InventoryItemInsightCalculatorProps {
  inventoryItem: InventoryItem;
  inventoryItemInsight: InventoryItemInsight;
}

export const InventoryItemInsightCalculator = ({
  inventoryItem,
  inventoryItemInsight,
}: InventoryItemInsightCalculatorProps) => {
  const { data: supplyChainPhases, isLoading: isLoadingSupplyChainPhases } =
    useFetchSupplyChainPhases();

  const { mutate: updateLeadTime, isLoading: isLoadingUpdateInventoryItemLeadTime } =
    useUpdateInventoryItemLeadTime(inventoryItem.id);

  const { days, updateItemDays } = useInventoryItemDaysAverage(inventoryItem.id);

  const [stockPeriod, setStockPeriod] = useState<StockPeriodOption | null>(null);

  const openUpdateLeadTimeDialog = useOpenDialog(DialogId.UPDATE_INVENTORY_ITEM_LEAD_TIME);

  const { t } = useTranslation();

  const onUpdateTransportSupplyChainPhaseId = (id: number) => {
    if (id == -1) {
      openUpdateLeadTimeDialog({
        inventoryItemInsight: inventoryItemInsight,
        days: inventoryItem.leadTime.transportDurationInDays,
        type: SupplyChainPhaseType.TRANSPORT,
      });
    } else {
      doUpdateLeadTime(inventoryItemInsight.leadTime.manufacturingSupplyChainPhaseId, id);
    }
  };

  const onUpdateManufacturingSupplyChainPhaseId = (id: number) => {
    if (id == -1) {
      openUpdateLeadTimeDialog({
        inventoryItemInsight: inventoryItemInsight,
        days: inventoryItem.leadTime.manufacturingDurationInDays,
        type: SupplyChainPhaseType.MANUFACTURING,
      });
    } else {
      doUpdateLeadTime(id, inventoryItemInsight.leadTime.transportSupplyChainPhaseId);
    }
  };

  const doUpdateLeadTime = (
    manufacturingSupplyChainPhaseId: number | null,
    transportSupplyChainPhaseId: number | null
  ) => {
    updateLeadTime(
      {
        manufacturing_supply_chain_phase_id: manufacturingSupplyChainPhaseId,
        manufacturing_duration_in_days:
          manufacturingSupplyChainPhaseId || inventoryItem.leadTime.manufacturingDurationInDays,
        transport_supply_chain_phase_id: transportSupplyChainPhaseId,
        transport_duration_in_days:
          transportSupplyChainPhaseId || inventoryItem.leadTime.transportDurationInDays,
      },
      {
        onSuccess: () => {
          toast.success(
            t(tokens.automator.stock.settings.supply_chain_phases.updated_inventory_item_lead_time)
          );
        },
      }
    );
  };

  if (isLoadingSupplyChainPhases || isLoadingUpdateInventoryItemLeadTime) {
    return <Typography>Loading...</Typography>;
  }

  const recommendedRestockDate = (days: 7 | 14 | 30) => {
    switch (days) {
      case 7:
        return inventoryItemInsight!.recommendedRestockDate7d;
      case 14:
        return inventoryItemInsight!.recommendedRestockDate14d;
      case 30:
        return inventoryItemInsight!.recommendedRestockDate30d;
    }
  };

  const depletionDate = (days: 7 | 14 | 30) => {
    switch (days) {
      case 7:
        return inventoryItemInsight!.depletionDate7d;
      case 14:
        return inventoryItemInsight!.depletionDate14d;
      case 30:
        return inventoryItemInsight!.depletionDate30d;
    }
  };

  const leadTime =
    inventoryItemInsight.leadTime.transportDurationInDays +
    inventoryItemInsight.leadTime.manufacturingDurationInDays;

  const averageSales = (): number => {
    switch (days) {
      case 7:
        return inventoryItem.averageSalesPerDay7d || 0;
      case 14:
        return inventoryItem.averageSalesPerDay14d || 0;
      case 30:
        return inventoryItem.averageSalesPerDay30d || 0;
      default:
        return 0;
    }
  };

  const amount = stockPeriod ? stockPeriod.days * averageSales() : null;

  return (
    <Stack
      direction="column"
      gap={1}
      width={'100%'}
      paddingRight={3}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">
          {t(tokens.automator.stock.inventory_item_insight.lead_time)}
        </Typography>
        <Typography variant="body2">
          {leadTime} {t(tokens.automator.stock.inventory_item_insight.days)}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2">
          {t(tokens.automator.stock.inventory_item_insight.transport)}
        </Typography>

        <Stack direction="row">
          <ToggleButtonGroup
            color="primary"
            value={inventoryItemInsight.leadTime.transportSupplyChainPhaseId || -1}
            exclusive
            onChange={(_, value) => onUpdateTransportSupplyChainPhaseId(value as number)}
            aria-label="Platform"
          >
            {supplyChainPhases!
              .filter((phase) => phase.type === SupplyChainPhaseType.TRANSPORT)
              .map((phase) => (
                <ToggleButton
                  style={{ minWidth: 70 }}
                  key={phase.id}
                  value={phase.id}
                >
                  {phase.durationInDays + ''}
                </ToggleButton>
              ))}

            <ToggleButton
              key={0}
              style={{ minWidth: 70 }}
              value={-1}
            >
              ...
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={2}
        borderBottom={0.1}
      >
        <Typography variant="body2">
          {t(tokens.automator.stock.inventory_item_insight.manufacturing)}
        </Typography>

        <Stack direction="row">
          <ToggleButtonGroup
            color="primary"
            value={inventoryItemInsight.leadTime.manufacturingSupplyChainPhaseId || -1}
            exclusive
            onChange={(_, value) => onUpdateManufacturingSupplyChainPhaseId(value as number)}
            aria-label="Platform"
          >
            {supplyChainPhases!
              .filter((phase) => phase.type === SupplyChainPhaseType.MANUFACTURING)
              .map((phase) => (
                <ToggleButton
                  style={{ minWidth: 70 }}
                  key={phase.id}
                  value={phase.id}
                >
                  {phase.durationInDays + ''}
                </ToggleButton>
              ))}

            <ToggleButton
              key={0}
              style={{ minWidth: 70 }}
              value={-1}
            >
              ...
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingTop={1}
        paddingBottom={2}
        marginBottom={1}
        borderBottom={0.1}
      >
        <Typography variant="body2">
          {t(tokens.automator.stock.inventory_item_insight.average_sales_per_day)}
        </Typography>

        <StockAverageSalesToggleButtons
          selectedDays={days}
          onChange={(newDays) => {
            updateItemDays(newDays);
          }}
          averageSalesPerDay7d={inventoryItem.averageSalesPerDay7d}
          averageSalesPerDay14d={inventoryItem.averageSalesPerDay14d}
          averageSalesPerDay30d={inventoryItem.averageSalesPerDay30d}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          gap={0.25}
          alignItems="center"
        >
          <EventBusyIcon color="primary" />
          <Typography variant="body2">
            {t(tokens.automator.stock.inventory_item_insight.depletion_date)}
          </Typography>
        </Stack>

        <Typography variant="body2">
          {depletionDate(days)
            ? getShortenedAlphabeticFormatFromDate(new Date(depletionDate(days)!), false, true)
            : '-'}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          gap={0.25}
          alignItems="center"
        >
          <ScheduleIcon color="warning" />
          <Typography variant="body2">
            {t(tokens.automator.stock.inventory_item_insight.restock_date)}
          </Typography>
        </Stack>

        <Typography variant="body2">
          {recommendedRestockDate(days)
            ? getShortenedAlphabeticFormatFromDate(
                new Date(recommendedRestockDate(days)!),
                false,
                true
              )
            : '-'}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Stack
            direction="row"
            gap={0.25}
            alignItems="center"
          >
            <SignalCellularAltIcon color="success" />
            <Typography
              variant="body2"
              noWrap
            >
              {t(tokens.automator.stock.inventory_item_insight.amount)}
            </Typography>
          </Stack>

          <StockPeriodDropdown
            selected={stockPeriod}
            onSelect={setStockPeriod}
            averageSales={averageSales()}
          />
        </Stack>

        <Typography variant="body2"> {roundDownTo1Decimal(amount)}</Typography>
      </Stack>
    </Stack>
  );
};
