import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';

export enum StockPeriod {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THREE_WEEKS = 'THREE_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  FOUR_MONTHS = 'FOUR_MONTHS',
  FIVE_MONTHS = 'FIVE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  SEVEN_MONTHS = 'SEVEN_MONTHS',
  EIGHT_MONTHS = 'EIGHT_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TEN_MONTHS = 'TEN_MONTHS',
}

export interface StockPeriodOption {
  key: StockPeriod;
  value: string;
  days: number;
  units: number | null;
}

interface StockPeriodDropdownProps {
  selected: StockPeriodOption | null;
  onSelect: (option: StockPeriodOption) => void;
  averageSales: number | null;
}

export const StockPeriodDropdown = ({
  onSelect,
  selected,
  averageSales,
}: StockPeriodDropdownProps) => {
  const { t } = useTranslation();

  const options: StockPeriodOption[] = [
    {
      key: StockPeriod.ONE_WEEK,
      value: t(tokens.automator.stock.stock_period.one_week),
      days: 7,
      units: averageSales && 7 * averageSales,
    },
    {
      key: StockPeriod.TWO_WEEKS,
      value: t(tokens.automator.stock.stock_period.two_weeks),
      days: 14,
      units: averageSales && 14 * averageSales,
    },
    {
      key: StockPeriod.THREE_WEEKS,
      value: t(tokens.automator.stock.stock_period.three_weeks),
      days: 21,
      units: averageSales && 21 * averageSales,
    },
    {
      key: StockPeriod.ONE_MONTH,
      value: t(tokens.automator.stock.stock_period.one_month),
      days: 30,
      units: averageSales && 30 * averageSales,
    },
    {
      key: StockPeriod.TWO_MONTHS,
      value: t(tokens.automator.stock.stock_period.two_months),
      days: 60,
      units: averageSales && 60 * averageSales,
    },
    {
      key: StockPeriod.THREE_MONTHS,
      value: t(tokens.automator.stock.stock_period.three_months),
      days: 90,
      units: averageSales && 90 * averageSales,
    },
    {
      key: StockPeriod.FOUR_MONTHS,
      value: t(tokens.automator.stock.stock_period.four_months),
      days: 120,
      units: averageSales && 120 * averageSales,
    },
    {
      key: StockPeriod.FIVE_MONTHS,
      value: t(tokens.automator.stock.stock_period.five_months),
      days: 150,
      units: averageSales && 150 * averageSales,
    },
    {
      key: StockPeriod.SIX_MONTHS,
      value: t(tokens.automator.stock.stock_period.six_months),
      days: 180,
      units: averageSales && 180 * averageSales,
    },
    {
      key: StockPeriod.SEVEN_MONTHS,
      value: t(tokens.automator.stock.stock_period.seven_months),
      days: 210,
      units: averageSales && 210 * averageSales,
    },
    {
      key: StockPeriod.EIGHT_MONTHS,
      value: t(tokens.automator.stock.stock_period.eight_months),
      days: 240,
      units: averageSales && 240 * averageSales,
    },
    {
      key: StockPeriod.NINE_MONTHS,
      value: t(tokens.automator.stock.stock_period.nine_months),
      days: 270,
      units: averageSales && 270 * averageSales,
    },
    {
      key: StockPeriod.TEN_MONTHS,
      value: t(tokens.automator.stock.stock_period.ten_months),
      days: 300,
      units: averageSales && 300 * averageSales,
    },
  ];

  useEffect(() => {
    if (selected == null) {
      onSelect(options[5]);
    }
  }, [selected]);

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      size="small"
      value={selected?.key}
      SelectProps={{ native: true }}
      onChange={(e) => {
        onSelect(options.find((option) => option.key == (e.target.value as StockPeriod))!);
      }}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value +
            (option.units
              ? ` (${roundDownTo1Decimal(option.units)} ${t(
                  tokens.automator.stock.recommended_restock_reports_table.units
                )})`
              : '')}
        </option>
      ))}
    </TextField>
  );
};
