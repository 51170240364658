import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AdminAccount from '../../../domain/automator/account/AdminAccount';
import { StampCodesAccountReport } from '../../../domain/automator/account/StampCodesReport';

interface AdminAccountRow {
  account: AdminAccount;
  weeks: number[];
  report: StampCodesAccountReport;
}

export const StampCodesAccountReportRow = ({ account, weeks, report }: AdminAccountRow) => {
  const getWeekReport = (week: number) => {
    return report.weeklyReports.find((it) => {
      return it.week == week;
    });
  };

  const firstWeekReport = getWeekReport(weeks[0]);
  const secondWeekReport = getWeekReport(weeks[1]);
  const thirdWeekReport = getWeekReport(weeks[2]);
  const fourthWeekReport = getWeekReport(weeks[3]);
  const fifthWeekReport = getWeekReport(weeks[4]);

  return (
    <TableRow key={account.id}>
      <TableCell>{account.id}</TableCell>
      <TableCell>{account.emailAddress}</TableCell>

      <TableCell align="right">{firstWeekReport ? firstWeekReport.usage : '-'} </TableCell>
      <TableCell align="right">{secondWeekReport ? secondWeekReport.usage : '-'} </TableCell>
      <TableCell align="right">{thirdWeekReport ? thirdWeekReport.usage : '-'} </TableCell>
      <TableCell align="right">{fourthWeekReport ? fourthWeekReport.usage : '-'} </TableCell>
      <TableCell align="right">{fifthWeekReport ? fifthWeekReport.usage : '-'} </TableCell>
    </TableRow>
  );
};

export default StampCodesAccountReportRow;
