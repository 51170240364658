import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import CurrencyTextField from '../../shared/CurrencyTextField';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { InventoryItemMultiSelect } from '../components/InventoryItemMultiSelect';
import ReducedInventoryItem from '../../../../domain/automator/stock/ReducedInventoryItem';
import { useState } from 'react';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import ToggleButton from '@mui/lab/ToggleButton';
import { SupplierDropdownSelect } from '../components/SupplierDropdownSelect';
import { ActionIcon } from '../../orders/components/ActionIcon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SalesVariantInfo } from '../components/SalesVariantInfo';

interface RestockFormLineProps {
  onUpdate: (item: EditRestockItem) => void;
  onRemove?: () => void;
  item?: EditRestockItem;
  supplierId: number | null;
  canUpdateQuantity?: boolean;
}

export interface EditRestockItem {
  inventoryItem: ReducedInventoryItem;
  supplierId: number | null;
  quantity: number;
  unitPriceInCents: number | null;
  unitTransportCostInCents: number | null;
  totalCubicMeters: number | null;
  totalWeightInKg: number | null;
  daysUntilDelivery: number;
  status: RestockItemStatus;
  metricOption: RestockItemMetricOption;
}

export enum RestockItemMetricOption {
  BY_UNIT = 'BY_UNIT',
  TOTAL = 'TOTAL',
}

export const RestockItemForm = ({
  onUpdate,
  onRemove,
  item: initialItem,
  supplierId,
  canUpdateQuantity = true,
}: RestockFormLineProps) => {
  const createNewRestockItem = () => {
    return {
      inventoryItem: { id: 0, supplierIds: [], salesVariants: [] },
      supplierId: supplierId,
      quantity: 1,
      unitPriceInCents: 0,
      unitTransportCostInCents: 0,
      totalCubicMeters: 0,
      totalWeightInKg: 0,
      daysUntilDelivery: 0,
      status: RestockItemStatus.DELIVERED,
      metricOption: RestockItemMetricOption.BY_UNIT,
    };
  };

  const { t } = useTranslation();

  const [item, setItem] = useState<EditRestockItem>(
    initialItem ? initialItem : createNewRestockItem()
  );

  const onUpdateInventoryItemId = (inventoryItem: ReducedInventoryItem) => {
    const newItem = {
      ...item,
      inventoryItem: inventoryItem,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateQuantity = (newQuantity: number) => {
    const newItem = {
      ...item,
      quantity: newQuantity,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateUnitPrice = (newUnitPrice: number) => {
    const newItem = {
      ...item,
      unitPriceInCents: newUnitPrice,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateUnitTransportCost = (newUnitTransportCost: number) => {
    const newItem = {
      ...item,
      unitTransportCostInCents: newUnitTransportCost,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateTotalWeight = (newTotalWeight: number) => {
    const newItem = {
      ...item,
      totalWeightInKg: newTotalWeight,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateTotalSize = (newTotalSize: number) => {
    const newItem = {
      ...item,
      totalCubicMeters: newTotalSize,
    };
    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateMetricOption = (option: RestockItemMetricOption) => {
    if (option == item.metricOption) return;

    switch (option) {
      case RestockItemMetricOption.TOTAL: {
        const newItem = {
          ...item,
          unitTransportCostInCents: item.unitTransportCostInCents
            ? item.unitTransportCostInCents * item.quantity
            : null,
          unitPriceInCents: item.unitPriceInCents ? item.unitPriceInCents * item.quantity : null,
          totalCubicMeters: item.totalCubicMeters ? item.totalCubicMeters * item.quantity : null,
          totalWeightInKg: item.totalWeightInKg ? item.totalWeightInKg * item.quantity : null,
          metricOption: option,
        };

        setItem(newItem);
        onUpdate(newItem);
        break;
      }
      case RestockItemMetricOption.BY_UNIT: {
        const newItem = {
          ...item,
          unitTransportCostInCents: item.unitTransportCostInCents
            ? item.unitTransportCostInCents / item.quantity
            : null,
          unitPriceInCents: item.unitPriceInCents ? item.unitPriceInCents / item.quantity : null,
          totalCubicMeters: item.totalCubicMeters ? item.totalCubicMeters / item.quantity : null,
          totalWeightInKg: item.totalWeightInKg ? item.totalWeightInKg / item.quantity : null,
          metricOption: option,
        };

        setItem(newItem);
        onUpdate(newItem);
        break;
      }
    }
  };

  const onUpdateSupplier = (supplierId: number) => {
    const newItem = {
      ...item,
      supplierId: supplierId,
    };

    setItem(item);
    onUpdate(newItem);
  };

  const onUpdateStatus = (status: RestockItemStatus) => {
    const newItem = {
      ...item,
      status: status,
      daysUntilDelivery: 0,
    };
    setItem(newItem);
    onUpdate(newItem);
  };

  const onUpdateDaysUntilDelivery = (days: number) => {
    const newItem = {
      ...item,
      status: days <= 0 ? RestockItemStatus.DELIVERED : RestockItemStatus.ORDERED,
      daysUntilDelivery: days,
    };

    setItem(newItem);
    onUpdate(newItem);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
    >
      <Stack
        width={440}
        direction="column"
        gap={0.25}
      >
        {item.inventoryItem.salesVariants.length == 0 && (
          <InventoryItemMultiSelect
            multiple={false}
            supplierId={supplierId}
            inventoryItemIds={[item.inventoryItem.id]}
            onSelect={(newInventoryItem) => {
              onUpdateInventoryItemId(newInventoryItem[0]);
            }}
            isDisabled={canUpdateQuantity == false}
          />
        )}

        {item.inventoryItem.salesVariants.length > 0 && (
          <SalesVariantInfo
            salesVariant={item.inventoryItem.salesVariants[0]}
            hasSiblingVariant={item.inventoryItem.salesVariants.length > 0}
            date={null}
          />
        )}

        <Box width={200}>
          <SupplierDropdownSelect
            supplierId={item.supplierId}
            onSelect={onUpdateSupplier}
          />
        </Box>
      </Stack>

      <Box width={100}>
        <TextField
          label={t(tokens.automator.stock.restock_dialog.quantity)}
          variant="outlined"
          value={item.quantity}
          disabled={!canUpdateQuantity}
          onChange={(e) => onUpdateQuantity(parseInt(e.target.value))}
        />
      </Box>

      <Stack
        direction="column"
        gap={0.25}
      >
        <ToggleButtonGroup
          size="small"
          value={item.metricOption}
          exclusive
          onChange={(_, newValue) => {
            if (newValue) onUpdateMetricOption(newValue);
          }}
          aria-label={
            t(tokens.automator.stock.restock_dialog.metric_option.metric_option) as string
          }
        >
          <ToggleButton
            value={RestockItemMetricOption.TOTAL}
            aria-label={t(tokens.automator.stock.restock_dialog.metric_option.total) as string}
          >
            {t(tokens.automator.stock.restock_dialog.metric_option.total)}
          </ToggleButton>
          <ToggleButton
            value={RestockItemMetricOption.BY_UNIT}
            aria-label={t(tokens.automator.stock.restock_dialog.metric_option.by_unit) as string}
          >
            {t(tokens.automator.stock.restock_dialog.metric_option.by_unit)}
          </ToggleButton>
        </ToggleButtonGroup>

        <Stack
          direction="row"
          width={200}
          gap={0.25}
        >
          <CurrencyTextField
            value={item.unitPriceInCents || 0}
            onChange={onUpdateUnitPrice}
            label={t(tokens.automator.stock.restock_dialog.unit_price) as string}
          />

          <CurrencyTextField
            value={item.unitTransportCostInCents || 0}
            onChange={onUpdateUnitTransportCost}
            label={t(tokens.automator.stock.restock_dialog.unit_transport_cost) as string}
          />
        </Stack>

        <Stack
          direction="row"
          gap={0.25}
          width={200}
        >
          <TextField
            label={t(tokens.automator.stock.restock_dialog.volume)}
            variant="outlined"
            value={item.totalCubicMeters}
            onChange={(e) => onUpdateTotalSize(parseInt(e.target.value))}
          />

          <TextField
            label={t(tokens.automator.stock.restock_dialog.weight)}
            variant="outlined"
            value={item.totalWeightInKg}
            onChange={(e) => onUpdateTotalWeight(parseInt(e.target.value))}
            inputProps={{
              input: {
                startAdornment: <InputAdornment position="start">kg</InputAdornment>,
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          gap={2}
        >
          <ToggleButtonGroup
            value={item.status}
            exclusive
            onChange={(_, newValue) => {
              if (newValue) onUpdateStatus(newValue);
            }}
            aria-label="delivery status"
          >
            <ToggleButton
              value={RestockItemStatus.DELIVERED}
              aria-label="delivered"
            >
              {t(tokens.automator.stock.product_stock_status.delivered)}
            </ToggleButton>
            <ToggleButton
              value={RestockItemStatus.ORDERED}
              aria-label="ordered"
            >
              {t(tokens.automator.stock.product_stock_status.ordered)}
            </ToggleButton>
          </ToggleButtonGroup>

          {item.status == RestockItemStatus.ORDERED && (
            <Box width={300}>
              <TextField
                label={t(tokens.automator.stock.restock_dialog.days_until_delivery)}
                variant="outlined"
                value={item.daysUntilDelivery}
                onChange={(e) => onUpdateDaysUntilDelivery(parseInt(e.target.value))}
              />
            </Box>
          )}
        </Stack>
      </Stack>

      {onRemove && (
        <ActionIcon
          onClick={onRemove}
          icon={<CancelOutlinedIcon />}
          tooltip={''}
        />
      )}
    </Stack>
  );
};
