import { tokens } from '../../../locales/translationTokens';
import { ProductListTable } from './components/ProductListTable';
import AccountPage from '../../../components/AccountPage';
import { useFetchAutomatorProducts } from '../../../api/automator/products/useFetchAutomatorProducts';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import ActionButton from '../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ProductGroupChip } from './components/ProductGroupChip';
import ProductsPageSearchBar from './components/ProductsPageSearchBar';
import AutomatorProduct from '../../../domain/automator/products/AutomatorProduct';
import { useFetchProductGroups } from '../../../api/automator/products/useFetchProductGroups';
import { ActionIcon } from '../orders/components/ActionIcon';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';

const ProductsPage = () => {
  const { data: loadedProducts, isLoading: isLoadingProducts } = useFetchAutomatorProducts();

  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<AutomatorProduct[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const openCreateProductGroupDialog = useOpenDialog(DialogId.CREATE_PRODUCT_GROUP);
  const openUpdateProductsDialog = useOpenDialog(DialogId.UPDATE_PRODUCTS);

  const { t } = useTranslation();

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false); // Indicating the rendering is complete
    }
  }, [searchedProducts]);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setIsSearching(true);
      if (query === '') {
        setSearchedProducts(null);
      } else if (loadedProducts) {
        setSearchedProducts(
          loadedProducts.products.filter(
            (product) =>
              product.title.toLowerCase().includes(query.toLowerCase()) ||
              product.ean.toLowerCase().includes(query.toLowerCase()) ||
              product.offers.some(
                (offer) => offer.internalReference?.toLowerCase().includes(query.toLowerCase())
              )
          )
        );
      }
    }, 500),
    [loadedProducts]
  );

  const onSelectGroup = (productGroupId: number) => {
    const newGroupIds = selectedGroupIds.includes(productGroupId)
      ? selectedGroupIds.filter((id) => id !== productGroupId)
      : [...selectedGroupIds, productGroupId];
    setSelectedGroupIds(newGroupIds);

    setIsSearching(true);
    if (newGroupIds.length > 0) {
      setSearchedProducts(
        loadedProducts!.products.filter((product) =>
          product.productGroupIds.some((groupId) => newGroupIds.includes(groupId))
        )
      );
    } else {
      setSearchedProducts(null);
    }
  };

  return (
    <AccountPage
      title={tokens.automator.products.products}
      titleExtra={
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
        >
          <ProductsPageSearchBar
            isDisabled={isLoadingProducts}
            onSearch={debouncedSearch}
          />

          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
          >
            <Stack
              direction="row"
              gap={0.5}
            >
              {productGroups?.map((productGroup) => (
                <ProductGroupChip
                  key={productGroup.id}
                  productGroup={productGroup}
                  onClick={() => onSelectGroup(productGroup.id)}
                  isSelected={selectedGroupIds.includes(productGroup.id)}
                />
              ))}
            </Stack>

            <ActionIcon
              tooltip={t(tokens.automator.products.dialogs.create_group.create_group)}
              onClick={() => {
                openCreateProductGroupDialog();
              }}
              icon={<PlusIcon />}
            />
          </Stack>
        </Stack>
      }
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.products.dialogs.update_products.update_products)}
          onClick={() => {
            loadedProducts &&
              openUpdateProductsDialog({
                products: loadedProducts?.products || [],
              });
          }}
          color="primary"
        />,
      ]}
    >
      <ProductListTable
        products={searchedProducts ? searchedProducts : loadedProducts?.products}
        isLoading={isLoadingProducts || isLoadingProductGroups || isSearching}
      />
    </AccountPage>
  );
};

export default ProductsPage;
