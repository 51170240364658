import { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RecommendedRestockReport from '../../../../domain/automator/stock/RecommendedRestockReport';
import { RecommendedRestockReportTableRow } from './RecommendedRestockReportTableRow';
import { tokens } from '../../../../locales/translationTokens';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import { StockPeriodOption } from './StockPeriodDropdown';

interface RecommendedRestockReportGroupTableRowProps {
  month: number;
  year: number;
  reports: RecommendedRestockReport[];
}

export const RecommendedRestockReportGroupTableRow = ({
  month,
  year,
  reports,
}: RecommendedRestockReportGroupTableRowProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedStockPeriods, setSelectedStockPeriods] = useState<{
    [key: string]: StockPeriodOption | null;
  }>({});

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleStockPeriodChange = (reportId: number, option: StockPeriodOption | null) => {
    setSelectedStockPeriods((prev) => ({
      ...prev,
      [reportId]: option,
    }));
  };

  const date = new Date(year, month - 1);
  const formattedDate = date.toLocaleString('nl-NL', { month: 'long', year: 'numeric' });

  const totalCost = reports.reduce((sum, report) => {
    const stockPeriod = selectedStockPeriods[report.id];
    const days = stockPeriod?.days ?? 0;
    const units = report.averageSales30d ? report.averageSales30d * days : 0;
    return sum + units * (report.latestInventoryStock.stockValues.unitPriceInCents || 0);
  }, 0);

  const totalVolume = reports.reduce((sum, report) => {
    const stockPeriod = selectedStockPeriods[report.id];
    const days = stockPeriod?.days ?? 30;
    const units = report.averageSales30d ? report.averageSales30d * days : 0;
    return sum + units * (report.latestInventoryStock.stockValues.totalCubicMeters || 0);
  }, 0);

  return (
    <>
      <TableRow
        style={{ cursor: 'pointer' }}
        onClick={handleToggle}
      >
        <TableCell>
          <Typography>{formattedDate}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {reports.length}{' '}
            {t(tokens.automator.stock.recommended_restock_reports_table.inventory_items)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{convertCentsToEuros(totalCost, true)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{roundDownTo1Decimal(totalVolume)} m³</Typography>
        </TableCell>
        <TableCell>
          <IconButton size="small">
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={7}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t(tokens.automator.stock.recommended_restock_reports_table.inventory_item)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {reports.map((report) => (
                  <RecommendedRestockReportTableRow
                    key={`${report.id}-${month}-${year}`}
                    recommendedRestockReport={report}
                    stockPeriodOption={selectedStockPeriods[report.id] || null}
                    onStockPeriodChange={handleStockPeriodChange}
                  />
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecommendedRestockReportGroupTableRow;
