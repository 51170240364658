import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import * as React from 'react';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { StockAdjustmentDirection } from '../../../../domain/automator/stock/StockAdjustmentDirection';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { Box } from '@mui/system';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';

interface ProductStockInsightTableRowProps {
  inventoryItem?: InventoryItem;
  type: ProductStockInsightType;
  date?: string;
  quantity: number;
  direction: StockAdjustmentDirection;
}

export type ProductStockInsightType = StockAdjustmentType | 'CURRENT' | 'PREVIOUS';

export const ProductStockInsightTableRow = ({
  inventoryItem,
  type,
  date,
  quantity,
  direction,
}: ProductStockInsightTableRowProps) => {
  const { t } = useTranslation();

  const openStocktakeDialog = useOpenDialog(DialogId.STOCKTAKE);

  const determineTypeLabel = (type: ProductStockInsightType) => {
    switch (type) {
      case StockAdjustmentType.RESTOCK:
        return tokens.automator.stock.product_stock_insight.type.restock;
      case StockAdjustmentType.SALES:
        return tokens.automator.stock.product_stock_insight.type.sales;
      case StockAdjustmentType.STOCKTAKE:
        return tokens.automator.stock.product_stock_insight.type.stocktake;
      case StockAdjustmentType.RETURN:
        return tokens.automator.stock.product_stock_insight.type.return;
      case 'CURRENT':
        return tokens.automator.stock.product_stock_insight.type.current;
      case 'PREVIOUS':
        return new Date(date!).getDay() == 1
          ? tokens.automator.stock.product_stock_insight.type.previous
          : tokens.automator.stock.product_stock_insight.type.from_bol;
    }
  };

  const hasBorderBottom = type === 'PREVIOUS';
  const hasBorderTop = type === 'PREVIOUS';

  return (
    <TableRow>
      <SlimTableCell
        paddingVertical={1}
        hasBorderBottom={hasBorderBottom}
        hasBorderTop={hasBorderTop}
      >
        <Typography variant="body2">{t(determineTypeLabel(type))}</Typography>
      </SlimTableCell>

      <SlimTableCell
        paddingVertical={1}
        hasBorderBottom={hasBorderBottom}
        hasBorderTop={hasBorderTop}
      >
        <Typography variant="body2">{date}</Typography>
      </SlimTableCell>

      <SlimTableCell
        paddingVertical={1}
        hasBorderBottom={hasBorderBottom}
        hasBorderTop={hasBorderTop}
      >
        <Typography variant="body2">
          {(direction == StockAdjustmentDirection.DECREASE && quantity > 0 ? '-' : '') + quantity}
        </Typography>
      </SlimTableCell>

      <SlimTableCell
        paddingVertical={1}
        hasBorderBottom={hasBorderBottom}
        hasBorderTop={hasBorderTop}
      >
        {type === 'CURRENT' ? (
          <ActionButton
            onClick={() => openStocktakeDialog({ inventoryItem: inventoryItem! })}
            size="small"
            variant="outlined"
            label={t(tokens.automator.stock.stocktake_dialog.stocktake)}
          />
        ) : (
          <Box />
        )}
      </SlimTableCell>
    </TableRow>
  );
};
