import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { EmailDocumentsList } from './EmailDocumentsList';
import HtmlEditor from '../../autoEmail/components/HtmlEditor';
import { RecipientTypeToggleButtonGroup } from './RecipientTypeToggleButtonGroup';
import Box from '@mui/material/Box';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { useGetEmailMessageDraft } from '../../../../store/emailMessageDrafts/useGetEmailMessageDraft';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { EmailMessageDraft } from '../../../../store/emailMessageDrafts/emailMessageDraftSlice';
import {
  CreateEmailThreadData,
  useCreateEmailThreads,
} from '../../../../api/automator/emails/useCreateEmailThreads';
import { toast } from 'react-hot-toast';
import { EmailThreadAggregateSelection } from './EmailThreadAggregateSelection';
import { TemplatesAndVariablesToggle } from './TemplatesAndVariablesToggle';
import Chip from '@mui/material/Chip';
import FileUpload from '../../shared/FileUpload';
import FileUploadForm from './FileUploadForm';
import { useFetchMessageTemplate } from '../../../../api/automator/emails/useFetchMessageTemplate';
import { useUpdateMessageTemplate } from '../../../../api/automator/emails/useUpdateMessageTemplate';
import {
  ExecuteAlertData,
  useExecuteProposedActions,
} from '../../../../api/automator/alerts/useExecuteProposedActions';
import { ReimbursementType } from '../../../../domain/automator/reimbursements/ReimbursementType';
import { ReimbursementTypeToggleButtons } from './ReimbursementTypeToggleButtons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import { useFetchMessageTemplates } from '../../../../api/automator/emails/useFetchMessageTemplates';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import { EmailVariableSelection } from '../../shipments/components/EmailVariableSelection';
import determineDefaultEmailMessageSubject from './helpers/determineDefaultEmailMessageSubject';
import determineDefaultEmailMessageBody from './helpers/determineDefaultEmailMessageBody';

interface StartEmailThreadFormProps {
  orderId: number | null;
  proposedActionId?: number;
  onClick: () => void;
  phase: ThreadPhase;
  aggregateId: number | null;
  aggregateIds: number[];
  messageTemplateId: number | null;
  body: string | null;
  mode: StartEmailThreadMode;
  recipientType: RecipientType;
  messageTemplateType: MessageTemplateType;
}

export enum StartEmailThreadMode {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
}

export const StartEmailThreadForm = ({
  orderId,
  proposedActionId,
  phase,
  aggregateId: initialAggregateId,
  aggregateIds,
  onClick,
  body,
  mode,
  messageTemplateId: initialMessageTemplateId,
  recipientType: initialRecipientType,
  messageTemplateType,
}: StartEmailThreadFormProps) => {
  const { t } = useTranslation();

  const [aggregateId, setAggregateId] = useState<number | null>(initialAggregateId);

  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  const [recipientType, setRecipientType] = useState<RecipientType>(initialRecipientType);

  const [reimbursementType, setReimbursementType] = useState<ReimbursementType | null>(
    messageTemplateType == MessageTemplateType.REIMBURSEMENT_REQUEST ? ReimbursementType.LIM : null
  );

  const { emailMessageDraft, setEmailMessageDraft } = useGetEmailMessageDraft(
    aggregateId,
    recipientType
  );

  const [messageTemplateId, setMessageTemplateId] = useState(initialMessageTemplateId);

  const { data: messageTemplates } = useFetchMessageTemplates(messageTemplateType, null, null);

  const { data: messageTemplate } = useFetchMessageTemplate(messageTemplateId);

  const { mutate: executeAlerts, isLoading: isLoadingExecuteAlerts } = useExecuteProposedActions();

  const { mutate: updateMessageTemplate, isLoading: isLoadingUpdateMessageTemplate } =
    useUpdateMessageTemplate(messageTemplateId ?? 0);

  useEffect(() => {
    if (
      messageTemplateType == MessageTemplateType.REIMBURSEMENT_REQUEST &&
      messageTemplates &&
      messageTemplateId == null
    ) {
      const messageTemplate = messageTemplates?.messageTemplates.find(
        (messageTemplate) =>
          messageTemplate.reimbursementType === reimbursementType &&
          messageTemplate.recipientType == recipientType
      );
      setMessageTemplateId(messageTemplate?.id || null);
    } else if (
      messageTemplateType == MessageTemplateType.INVESTIGATION_REQUEST &&
      messageTemplates
    ) {
      const messageTemplate = messageTemplates?.messageTemplates.find(
        (messageTemplate) => messageTemplate.recipientType == recipientType
      );
      setMessageTemplateId(messageTemplate?.id || null);
    }
  }, [messageTemplates]);

  useEffect(() => {
    if (messageTemplate) {
      setEmailMessageDraft({
        ...draft,
        messageTemplateId: messageTemplate.id,
        body: messageTemplate?.body,
        subject: messageTemplate.subject,
      });
    }
  }, [messageTemplate]);

  const getDraft = () => {
    if (emailMessageDraft) {
      return emailMessageDraft;
    } else {
      return {
        recipientType: recipientType,
        phase: phase,
        aggregateId: aggregateId,
        messageTemplateId: null,
        subject: determineDefaultEmailMessageSubject(recipientType),
        body: body || determineDefaultEmailMessageBody(recipientType),
        startWithCase: true,
        files: [],
      };
    }
  };

  const onSelectRecipientType = (recipientType: RecipientType) => {
    setEmailMessageDraft({
      ...draft,
      body:
        draft.messageTemplateId != null
          ? draft.body
          : determineDefaultEmailMessageBody(recipientType),
      subject:
        draft.messageTemplateId != null
          ? draft.subject
          : determineDefaultEmailMessageSubject(recipientType),
      recipientType,
    });

    setRecipientType(recipientType);

    if (messageTemplateType == MessageTemplateType.REIMBURSEMENT_REQUEST) {
      if (!determineSelectableReimbursementTypes().includes(reimbursementType!)) {
        setReimbursementType(ReimbursementType.LIM);
      }

      const messageTemplate = messageTemplates?.messageTemplates.find(
        (messageTemplate) =>
          messageTemplate.reimbursementType === reimbursementType &&
          messageTemplate.recipientType == recipientType
      );
      setMessageTemplateId(messageTemplate?.id || null);
    } else if (messageTemplateType == MessageTemplateType.INVESTIGATION_REQUEST) {
      const messageTemplate = messageTemplates?.messageTemplates.find(
        (messageTemplate) => messageTemplate.recipientType == recipientType
      );
      setMessageTemplateId(messageTemplate?.id || null);
    }
  };

  const onSelectReimbursementType = (reimbursementType: ReimbursementType) => {
    setReimbursementType(reimbursementType);

    const messageTemplate = messageTemplates?.messageTemplates.find(
      (messageTemplate) =>
        messageTemplate.reimbursementType === reimbursementType &&
        messageTemplate.recipientType == recipientType
    );

    setMessageTemplateId(messageTemplate?.id || null);
  };

  const { mutate: createEmailThreads, isLoading } = useCreateEmailThreads(phase);

  const draft = getDraft();

  const onCreateEmail = (emailMessageDraft: EmailMessageDraft) => {
    if (mode == StartEmailThreadMode.SINGLE && !emailMessageDraft.aggregateId) {
      toast.error(
        t(tokens.automator.resolutions.dialogs.send_email.aggregate_id_needs_to_be_selected_error)
      );
      return;
    }

    const createRequest = (emailMessageDraft: EmailMessageDraft, aggregateId: number) => {
      return {
        order_item_id: phase === ThreadPhase.ORDER ? aggregateId : undefined,
        shipment_id: phase === ThreadPhase.SHIPMENT ? aggregateId : undefined,
        return_item_id: phase === ThreadPhase.RETURN ? aggregateId : undefined,
        email_template_id: emailMessageDraft.messageTemplateId,
        subject: emailMessageDraft.subject,
        body: emailMessageDraft.body,
        recipient_type: emailMessageDraft.recipientType,
        phase: phase,
        is_start_with_resolution_case: emailMessageDraft.startWithCase,
        files: emailMessageDraft.files,
        reimbursement_type: reimbursementType,
      } as CreateEmailThreadData;
    };

    createEmailThreads(
      mode == StartEmailThreadMode.SINGLE
        ? [createRequest(emailMessageDraft, emailMessageDraft.aggregateId!)]
        : aggregateIds.map((aggregateId) => createRequest(emailMessageDraft, aggregateId)),
      {
        onSuccess: async () => {
          setEmailMessageDraft({
            ...draft,
            body: determineDefaultEmailMessageBody(RecipientType.CUSTOMER),
            subject: determineDefaultEmailMessageSubject(RecipientType.CUSTOMER),
            messageTemplateId: null,
            startWithCase: false,
          });
          onClick();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email.email_sent));
        },
      }
    );
  };

  const onExecuteAlertAction = (emailMessageDraft: EmailMessageDraft) => {
    executeAlerts(
      {
        proposed_action_ids: [proposedActionId],
        subject: emailMessageDraft.subject,
        body: emailMessageDraft.body,
        is_start_with_resolution_case: emailMessageDraft.startWithCase,
        files: emailMessageDraft.files,
      } as ExecuteAlertData,
      {
        onSuccess: async () => {
          setEmailMessageDraft({
            ...draft,
            body: determineDefaultEmailMessageBody(RecipientType.CUSTOMER),
            subject: determineDefaultEmailMessageSubject(RecipientType.CUSTOMER),
            messageTemplateId: null,
            startWithCase: false,
          });
          onClick();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email.email_sent));
        },
      }
    );
  };

  const onUpdateMessageTemplate = () => {
    updateMessageTemplate(
      {
        name: messageTemplate?.name || '',
        subject: draft?.subject || '',
        body: draft.body,
      },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.resolutions.dialogs.update_email_template.email_template_updated)
          );
        },
      }
    );
  };

  const determinePhaseToken = (phase: ThreadPhase) => {
    switch (phase) {
      case ThreadPhase.ORDER:
        return tokens.automator.orders.order;
      case ThreadPhase.SHIPMENT:
        return tokens.automator.shipments.shipment;
      case ThreadPhase.RETURN:
        return tokens.automator.returns.return;
    }
  };

  const determineSelectableRecipientTypes = () => {
    if (phase == ThreadPhase.ORDER) {
      return [RecipientType.CUSTOMER, RecipientType.BOL];
    }

    if (messageTemplateType != MessageTemplateType.MANUAL) {
      return [RecipientType.BOL, RecipientType.TRANSPORTER];
    }

    return [RecipientType.CUSTOMER, RecipientType.BOL, RecipientType.TRANSPORTER];
  };

  const determineSelectableReimbursementTypes = () => {
    if (recipientType == RecipientType.TRANSPORTER) {
      return [ReimbursementType.LIM, ReimbursementType.SHIPPING_LABEL];
    }

    return [
      ReimbursementType.LIM,
      ReimbursementType.RLIM,
      ReimbursementType.RETURN_LABEL,
      ReimbursementType.SHIPPING_LABEL,
    ];
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={3}
      >
        <Stack
          direction="column"
          gap={1.5}
          width={!initialMessageTemplateId ? '70%' : '100%'}
        >
          <Table>
            <TableBody>
              {mode == StartEmailThreadMode.SINGLE && (
                <TableRow>
                  <SlimTableCell
                    hasBorderBottom={false}
                    hasBorderTop={false}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      variant="body1"
                    >
                      {t(determinePhaseToken(phase) as string)}
                    </Typography>
                  </SlimTableCell>

                  <SlimTableCell
                    hasBorderBottom={false}
                    hasBorderTop={false}
                  >
                    <EmailThreadAggregateSelection
                      orderId={orderId!}
                      aggregateId={draft.aggregateId}
                      setAggregateId={(aggregateId) => {
                        setAggregateId(aggregateId);
                      }}
                      phaseOverwrite={phase}
                    />
                  </SlimTableCell>
                </TableRow>
              )}

              <TableRow>
                <SlimTableCell
                  hasBorderBottom={false}
                  hasBorderTop={false}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    variant="body1"
                  >
                    {t(tokens.common.recipient_type.recipient_type)}
                  </Typography>
                </SlimTableCell>

                <SlimTableCell
                  hasBorderBottom={false}
                  hasBorderTop={false}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <RecipientTypeToggleButtonGroup
                      onSelect={onSelectRecipientType}
                      value={draft.recipientType}
                      selectable={determineSelectableRecipientTypes()}
                    />

                    {mode == StartEmailThreadMode.BULK && (
                      <Chip
                        label={
                          aggregateIds.length +
                          ' ' +
                          t(tokens.automator.resolutions.dialogs.send_email.amount_of_recipients)
                        }
                        color="success"
                      />
                    )}
                  </Stack>
                </SlimTableCell>
              </TableRow>

              {reimbursementType && (
                <TableRow>
                  <SlimTableCell
                    hasBorderBottom={false}
                    hasBorderTop={false}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      variant="body1"
                    >
                      {t(tokens.automator.reimbursements.type.type)}
                    </Typography>
                  </SlimTableCell>
                  <SlimTableCell
                    hasBorderBottom={false}
                    hasBorderTop={false}
                  >
                    <ReimbursementTypeToggleButtons
                      value={reimbursementType}
                      onSelect={(reimbursementType) => {
                        onSelectReimbursementType(reimbursementType);
                      }}
                      selectable={determineSelectableReimbursementTypes()}
                    />
                  </SlimTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TextField
            id="subject-field"
            label={t(tokens.automator.resolutions.dialogs.send_email.subject)}
            variant="filled"
            fullWidth
            value={draft.subject}
            onChange={(e) => setEmailMessageDraft({ ...draft, subject: e.target.value })}
          />

          <HtmlEditor
            content={draft.body}
            onChange={(body) => {
              if (draft.body === body) {
                return;
              }
              setEmailMessageDraft({ ...draft, body });
            }}
            insertVariable={insertVariable}
          />

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={draft.startWithCase}
                  onChange={(event) =>
                    setEmailMessageDraft({
                      ...draft,
                      startWithCase: event.target.checked,
                    })
                  }
                  name="startWithCase"
                  color="primary"
                />
              }
              label={t(tokens.automator.resolutions.dialogs.send_email.start_with_case)}
            />
          </Box>

          {messageTemplate && messageTemplate!.documents.length > 0 ? (
            <EmailDocumentsList documents={messageTemplate!.documents} />
          ) : (
            <Box marginLeft={-1}>
              <FileUploadForm
                onChange={(fileUploads: FileUpload[]) =>
                  setEmailMessageDraft({
                    ...draft,
                    files: fileUploads,
                  })
                }
                multiple
              />
            </Box>
          )}

          <Stack
            direction="row"
            gap={2}
          >
            <ActionButton
              icon={<Email />}
              label={t(tokens.automator.resolutions.dialogs.send_email.send_email)}
              onClick={() =>
                proposedActionId ? onExecuteAlertAction(draft) : onCreateEmail(draft)
              }
              isLoading={isLoading}
              variant="contained"
              color="primary"
            />

            {(initialMessageTemplateId || messageTemplateType != MessageTemplateType.MANUAL) && (
              <ActionButton
                icon={<Email />}
                label={t(
                  tokens.automator.resolutions.dialogs.update_email_template.update_email_template
                )}
                onClick={() => onUpdateMessageTemplate()}
                isLoading={isLoadingUpdateMessageTemplate || isLoadingExecuteAlerts}
                variant="outlined"
                color="primary"
              />
            )}
          </Stack>
        </Stack>

        {!initialMessageTemplateId && messageTemplateType == MessageTemplateType.MANUAL && (
          <Box width="30%">
            <TemplatesAndVariablesToggle
              phase={phase}
              onSelectTemplate={(messageTemplateId) => {
                setMessageTemplateId(messageTemplateId);
              }}
              selectedTemplateId={draft.messageTemplateId}
              onSelectVariable={setInsertVariable}
            />
          </Box>
        )}

        {messageTemplateType != MessageTemplateType.MANUAL && (
          <Box width="30%">
            <EmailVariableSelection onSelectVariable={setInsertVariable} />
          </Box>
        )}
      </Stack>
    </form>
  );
};
