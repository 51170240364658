import AutomatorPage from '../../components/AutomatorPage';
import { useUploadStampCodes } from '../../api/automator/admin/useUploadStampCodes';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { AdminStampCodesReportTable } from './components/AdminStampCodesReportTable';
import { toast } from 'react-hot-toast';

const AdminStampCodesPage = () => {
  const { mutate } = useUploadStampCodes();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    const fileText = await selectedFile!.text();
    mutate(
      { file: fileText },
      {
        onSuccess: () => {
          toast.success('Stamp codes uploaded');
        },
      }
    );
  };

  return (
    <AutomatorPage
      buttons={[]}
      title="Stamp Codes"
    >
      <Stack
        direction="column"
        gap={3}
      >
        <div>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
          <button
            disabled={selectedFile == null}
            onClick={handleUpload}
          >
            Upload CSV
          </button>
        </div>

        <AdminStampCodesReportTable />
      </Stack>
    </AutomatorPage>
  );
};

export default AdminStampCodesPage;
