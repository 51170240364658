import Chip from '@mui/material/Chip';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { colors } from '@mui/material';

interface DistributionPartyChipProps {
  distributionParty: DistributionParty;
  variant?: 'filled' | 'outlined';
}

const DistributionPartyChip = ({
  distributionParty,
  variant = 'filled',
}: DistributionPartyChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (distributionParty: DistributionParty): string => {
    switch (distributionParty) {
      case DistributionParty.RETAILER:
        return tokens.automator.shipments.distribution_party.retailer;
      case DistributionParty.BOL:
        return tokens.automator.shipments.distribution_party.bol;
      case DistributionParty.MIXED:
        return tokens.automator.shipments.distribution_party.mixed;
      default:
        return '';
    }
  };

  const determineStyle = (variant: 'filled' | 'outlined') => {
    if (variant === 'filled') {
      return {
        borderRadius: 4,
        backgroundColor:
          distributionParty == DistributionParty.BOL ? colors.blue[500] : colors.deepPurple[400],
        color: 'white',
      };
    } else {
      return {
        borderRadius: 4,
      };
    }
  };

  return (
    <Chip
      style={determineStyle(variant)}
      size="small"
      label={t(determineLabel(distributionParty))}
      variant={variant}
      color={distributionParty == DistributionParty.BOL ? 'primary' : 'secondary'}
    />
  );
};

export default DistributionPartyChip;
