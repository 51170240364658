import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface TableRowLoadingProps {
  message: string;
}

export const TableRowLoading = ({ message }: TableRowLoadingProps) => {
  return (
    <TableRow>
      <TableCell colSpan={100}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="15px"
        >
          <CircularProgress />
          <Typography variant="body1">{message}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
