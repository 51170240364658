import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { CheckCircle } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { useAddShipmentTrackAndTrace } from '../../../../api/automator/shipments/useAddShipmentTrackAndTrace';

interface ShipmentTrackAndTraceTextFieldProps {
  shipmentId: number;
}

export const ShipmentTrackAndTraceTextField = ({
  shipmentId,
}: ShipmentTrackAndTraceTextFieldProps) => {
  const [trackAndTrace, setTrackAndTrace] = useState('');

  const { t } = useTranslation();

  const { mutate, isLoading } = useAddShipmentTrackAndTrace(shipmentId);

  const doUpdate = () => {
    if (trackAndTrace === '') {
      toast.error(
        t(tokens.automator.shipments.actions.add_track_and_trace.track_and_trace_cannot_be_empty)
      );
      return;
    }

    const data = {
      track_and_trace: trackAndTrace,
    };
    mutate(data, {
      onSuccess: async () => {
        toast.success(
          t(tokens.automator.shipments.actions.add_track_and_trace.track_and_trace_added)
        );
      },
    });
  };

  return (
    <TextField
      style={{ minWidth: 175 }}
      size="small"
      label={t(tokens.automator.shipments.actions.add_track_and_trace.track_and_trace)}
      value={trackAndTrace}
      onChange={(e) => {
        setTrackAndTrace(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              doUpdate();
            }}
          >
            {isLoading ? <CircularProgress size={10} /> : <CheckCircle />}
          </IconButton>
        ),
      }}
    />
  );
};
