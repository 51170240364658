import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import RestockItem from '../../../../domain/automator/stock/RestockItem';
import { useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { useDeleteRestockItem } from '../../../../api/automator/stock/useDeleteRestockItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface DeleteRestockItemFormProps {
  onClick?: () => void;
  restockItem: RestockItem;
}

export const DeleteRestockItemForm = ({ restockItem, onClick }: DeleteRestockItemFormProps) => {
  const [alsoRemoveInventoryStock, setAlsoRemoveInventoryStock] = useState(false);

  const { mutate: deleteRestockItem } = useDeleteRestockItem(restockItem.id);

  const { t } = useTranslation();

  const onSave = () => {
    deleteRestockItem(
      {
        also_remove_inventory_stock: alsoRemoveInventoryStock,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.delete_restock_item_dialog.restock_item_deleted));
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={alsoRemoveInventoryStock}
            onChange={(_, checked) => setAlsoRemoveInventoryStock(checked)}
            color="primary"
          />
        }
        label={t(tokens.automator.stock.delete_restock_item_dialog.also_remove_inventory_stock)}
      />
      <ActionButton
        color="primary"
        label={t(tokens.automator.stock.delete_restock_item_dialog.delete_restock_item)}
        onClick={onSave}
      />
    </Stack>
  );
};
