import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrderItemProductInfo } from './OrderItemProductInfo';
import Stack from '@mui/material/Stack';
import Order from '../../../../domain/automator/orders/Order';
import Checkbox from '@mui/material/Checkbox';
import { OrdersMode } from '../OrdersPage';
import { CancellationRequestChip } from './CancellationRequestChip';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';

interface OrderItemCardProps {
  order: Order;
  orderItem: OrderItem;
  distributionParty: DistributionParty | null;
  mode: OrdersMode;
  isSelected: boolean;
  onSelect: (orderItem: OrderItem) => void;
}

export const OrderItemCard = ({
  order,
  orderItem,
  distributionParty,
  mode,
  onSelect,
  isSelected,
}: OrderItemCardProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap={5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {mode == OrdersMode.BULK_ORDER_ITEMS && (
        <Checkbox
          checked={isSelected}
          onChange={() => onSelect(orderItem)}
        />
      )}

      <OrderItemProductInfo
        orderStatus={order.status}
        orderItemStatus={orderItem.status}
        quantity={orderItem.quantity}
        offer={orderItem.offer}
      />

      {distributionParty != null && distributionParty != orderItem.distributionParty && (
        <Chip
          size="small"
          color="info"
          label={
            orderItem.distributionParty == DistributionParty.BOL
              ? t(tokens.automator.orders.is_vvb)
              : t(tokens.automator.orders.is_non_vvb)
          }
        />
      )}

      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
      >
        <SpeedDialActionIcon
          orderId={order.id}
          orderItemId={orderItem.id}
          hasNote={order.hasNote}
        />
        <Box width={130}>{orderItem.cancellationRequest && <CancellationRequestChip />}</Box>
      </Stack>
    </Stack>
  );
};
