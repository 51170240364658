import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../locales/translationTokens';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';
import { useTranslation } from 'react-i18next';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { SupplyChainPhaseTableRow } from './SupplyChainPhaseTableRow';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface SupplyChainPhaseListTableProps {
  type: SupplyChainPhaseType;
  supplyChainPhases: SupplyChainPhase[];
}

export const SupplyChainPhaseListTable = ({
  type,
  supplyChainPhases,
}: SupplyChainPhaseListTableProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_SUPPLY_CHAIN_PHASE);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {type == SupplyChainPhaseType.MANUFACTURING
              ? t(tokens.automator.stock.settings.supply_chain_phases.type.manufacturing)
              : t(tokens.automator.stock.settings.supply_chain_phases.type.transport)}
          </TableCell>

          <TableCell />

          <TableCell align="right">
            <ActionButton
              label={t(tokens.automator.stock.settings.supply_chain_phases.add_phase)}
              variant="outlined"
              color="primary"
              onClick={() => openDialog({ type: type })}
            />
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {supplyChainPhases!.map((phase) => {
          return (
            <SupplyChainPhaseTableRow
              key={phase.id}
              supplyChainPhase={phase}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
