import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import Box from '@mui/material/Box';
import { EmailThreadOrderItemSelection } from './EmailThreadOrderItemSelection';
import { EmailThreadShipmentSelection } from './EmailThreadShipmentSelection';
import { EmailThreadReturnItemSelection } from './EmailThreadReturnItemSelection';
import Stack from '@mui/material/Stack';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipments } from '../../../../api/automator/orders/useFetchOrderShipments';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';
import { useEffect } from 'react';

interface EmailThreadAggregateSelectionProps {
  orderId: number;
  aggregateId: number | null;
  setAggregateId: (aggregateId: number) => void;
  phaseOverwrite?: ThreadPhase;
}

export const EmailThreadAggregateSelection = ({
  orderId,
  aggregateId,
  setAggregateId,
  phaseOverwrite,
}: EmailThreadAggregateSelectionProps) => {
  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(orderId);

  // NOTE: not really efficient to fetch all the returns and shipments for every order as we already know the current phase as thus what aggregates to use
  const { data: shipments, isLoading: isLoadingOrderShipments } = useFetchOrderShipments(orderId);
  const { data: returns, isLoading: isLoadingOrderReturns } = useFetchOrderReturns(orderId);

  const phase = phaseOverwrite || order?.currentPhase;

  useEffect(() => {
    if (!isLoadingOrder && !isLoadingOrderShipments && !isLoadingOrderReturns) {
      switch (phase) {
        case ThreadPhase.ORDER:
          if (order!.items.length === 1) {
            setAggregateId(order!.items[0].id);
          }
          return;

        case ThreadPhase.SHIPMENT:
          if (shipments!.length === 1) {
            const shipment = shipments![0];
            setAggregateId(shipment!.id);
          }
          return;

        case ThreadPhase.RETURN:
          if (returns!.length === 1) {
            const returnItems = returns![0].returnItems;
            if (returnItems.length === 1) {
              setAggregateId(returnItems[0].id);
            }
          }
          return;
      }
    }
  }, [isLoadingOrder, isLoadingOrderShipments, isLoadingOrderReturns]);

  if (isLoadingOrder || isLoadingOrderShipments || isLoadingOrderReturns) {
    return <div>Loading...</div>;
  }

  // const determineSelectablePhases = (returns: Return[], shipment?: Shipment | null) => {
  //   const selectablePhases = [ThreadPhase.ORDER];
  //
  //   if (shipment) {
  //     selectablePhases.push(ThreadPhase.SHIPMENT);
  //   }
  //
  //   if (returns.length > 0) {
  //     selectablePhases.push(ThreadPhase.RETURN);
  //   }
  //
  //   return selectablePhases;
  // };
  //

  return (
    <Stack
      direction="column"
      gap={1.5}
    >
      {/*{phase == null && (*/}
      {/*  <EmailPhaseToggleButtonGroup*/}
      {/*    onSelect={onUpdatePhase}*/}
      {/*    value={phase}*/}
      {/*    selectablePhases={determineSelectablePhases(returns!, shipment) || []}*/}
      {/*  />*/}
      {/*)}*/}

      <Box>
        {phase == ThreadPhase.ORDER && (
          <EmailThreadOrderItemSelection
            order={order!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {phase == ThreadPhase.SHIPMENT && (
          <EmailThreadShipmentSelection
            shipments={shipments!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {phase == ThreadPhase.RETURN && (
          <EmailThreadReturnItemSelection
            returns={returns!}
            selectedAggregateId={aggregateId}
            setAggregateId={setAggregateId}
          />
        )}
      </Box>
    </Stack>
  );
};
