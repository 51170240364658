import Typography from '@mui/material/Typography';
import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { getDateStringFromDate } from '../../../../helpers/getDateStringFromDate';
import ShipmentTransporterInfo from '../../shipments/components/ShipmentTransporterInfo';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipments } from '../../../../api/automator/orders/useFetchOrderShipments';
import * as React from 'react';
import { ReturnResolutionCaseInfo } from './ReturnResolutionCaseInfo';
import { useFetchOrderEvents } from '../../../../api/automator/orders/useFetchOrderEvents';
import { OrderEventType } from '../../../../domain/automator/orders/OrderEventType';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import getWeekNumberOfYear from '../../../../helpers/getWeekNumberOfYear';
import CollapsibleCard from '../../shared/CollapsibleCard';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';

interface OrderResolutionCaseInfoCardProps {
  resolutionCase: ResolutionCase;
}

export const OrderResolutionCaseInfoCard = ({
  resolutionCase,
}: OrderResolutionCaseInfoCardProps) => {
  const hasBolCaseNumber = resolutionCase.bolCaseNumber !== null;

  const orderId = resolutionCase.messageThread.orderId!;

  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(orderId);

  const { data: orderEvents, isLoading: isLoadingOrderEvents } = useFetchOrderEvents(orderId);

  const { data: shipments, isLoading: isLoadingShipments } = useFetchOrderShipments(orderId);

  const { data: returns, isLoading: isLoadingReturns } = useFetchOrderReturns(orderId);

  const { t } = useTranslation();

  if (isLoadingOrder || isLoadingShipments || isLoadingOrderEvents || isLoadingReturns) {
    return <div>Loading...</div>;
  }

  const latestOrderEvent = orderEvents!
    .filter((event) => event.type != OrderEventType.EXPECTED_DELIVERY_DATE)
    .sort((a, b) => {
      const timeA = new Date(`${a.date}T${a.time}Z`).getTime();
      const timeB = new Date(`${b.date}T${b.time}Z`).getTime();
      return timeB - timeA;
    })[0];

  return (
    <CollapsibleCard
      title={t(tokens.automator.resolutions.resolution_dossier_table.order_info)}
      isOpen={true}
    >
      <List>
        {hasBolCaseNumber && (
          <ListItem>
            <ListItemText
              primary={t(tokens.automator.resolutions.resolution_dossier_table.case_number)}
            />
            <Typography variant="body2">{resolutionCase.bolCaseNumber}</Typography>
          </ListItem>
        )}
        <ListItem>
          <ListItemText primary={t(tokens.automator.auto_mail.distribution_party)} />
          <DistributionPartyChip distributionParty={order!.distributionParty} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t(tokens.automator.order_summary.order_event_type)} />
          <Typography
            variant="body2"
            color="textPrimary"
          >
            {latestOrderEvent.type}
          </Typography>
        </ListItem>
        <ListItem>
          <ListItemText primary={t(tokens.automator.orders.order_placed_date)} />
          <Typography variant="body2">
            {getDateStringFromDate(new Date(order!.orderPlacedDate), false) +
              ' (W' +
              getWeekNumberOfYear(order!.orderPlacedDate) +
              ')'}
          </Typography>
        </ListItem>
        {shipments &&
          shipments.map((shipment) => (
            <ListItem key={shipment.id}>
              <ListItemText primary={t(tokens.automator.orders.expected_delivery_date)} />
              <Typography variant="body2">
                {getDateStringFromDate(new Date(shipment.expectedDeliveryDate), false) +
                  ' (W' +
                  getWeekNumberOfYear(shipment.expectedDeliveryDate) +
                  ')'}
              </Typography>
            </ListItem>
          ))}
        {shipments &&
          shipments.map((shipment) => (
            <ListItem key={shipment.id}>
              <ListItemText primary={t(tokens.automator.shipments.shipment)} />
              <ShipmentTransporterInfo shipment={shipment!} />
            </ListItem>
          ))}

        {returns &&
          returns.map((return_) => (
            <ListItem key={return_.id}>
              <ListItemText primary={t(tokens.automator.returns.return)} />
              {return_.returnItems.map((returnItem) => (
                <ReturnResolutionCaseInfo
                  key={returnItem.id}
                  returnItem={returnItem}
                />
              ))}
            </ListItem>
          ))}
      </List>
    </CollapsibleCard>
  );
};
