import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { OpenForwardEmailDialogButton } from '../../returns/components/OpenForwardEmailDialogButton';
import { useFetchThreadMessages } from '../../../../api/automator/emails/useFetchThreadMessages';
import OpenCreateResendDialogButton from './OpenCreateResendDialogButton';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import { OpenSendEmailThreadReplyButton } from './OpenSendEmailThreadReplyButton';
import { Box } from '@mui/system';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import CommentIcon from '@mui/icons-material/Comment';
import { Hub, Toll } from '@mui/icons-material';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import ReturnResolutionCasePopoverButton from './ReturnResolutionCasePopoverButton';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import NavigateToPickSessionButton from '../../pick-sessions/components/NavigateToPickSessionButton';
import GotoBolCaseActionButton from '../../pick-sessions/components/GotoBolCaseActionButton';

interface ResolutionCaseActionsProps {
  resolutionCase: ResolutionCase;
  hasOpenResend: boolean;
}

const OrderResolutionCaseActions = ({
  resolutionCase,
  hasOpenResend,
}: ResolutionCaseActionsProps) => {
  const { data: emailMessages, isLoading } = useFetchThreadMessages(
    resolutionCase.messageThread.id
  );

  const openViewOrderSummaryDialog = useOpenDialog(DialogId.ORDER_SUMMARY);
  const openUpdateOrderNoteDialog = useOpenDialog(DialogId.UPDATE_ORDER_NOTE_DIALOG);
  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const { t } = useTranslation();

  if (isLoading || !emailMessages) {
    return <>Loading...</>;
  }

  const forwardBody = emailMessages.messages.map((emailMessage) => emailMessage.body).join('\n\n');

  const orderId = resolutionCase.messageThread.orderId!;

  const bolOrderId = resolutionCase.messageThread.bolOrderId!;

  return (
    <Stack
      borderRadius={4}
      border="1px solid BLUE"
      direction={'row'}
      gap={0.5}
      alignItems="left"
      paddingY={1}
      paddingX={1}
    >
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
      >
        <Stack
          direction="column"
          alignItems="top"
          gap={0.25}
        >
          <Box>
            <OpenSendEmailThreadReplyButton
              resolutionCaseId={resolutionCase.id}
              messageThread={resolutionCase.messageThread}
            />
          </Box>

          <OpenForwardEmailDialogButton
            body={forwardBody}
            orderId={orderId}
            orderItemId={resolutionCase.messageThread.orderItemId}
            returnItemId={resolutionCase.messageThread.returnItemId}
            shipmentId={resolutionCase.messageThread.shipmentId}
            currentPhase={resolutionCase.messageThread.phase}
            type={ActionType.BUTTON}
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="top"
          gap={0.25}
        >
          <Box>
            <OpenCreateResendDialogButton
              isDisabled={hasOpenResend}
              orderId={orderId}
            />
          </Box>

          <ActionButton
            size="small"
            onClick={() => openViewOrderSummaryDialog({ query: bolOrderId })}
            label={t(tokens.automator.order_summary.order_summary)}
            variant="text"
            color="primary"
            icon={<Hub />}
          />
        </Stack>
      </Stack>

      <Stack
        direction="column"
        alignItems="top"
        gap={0.25}
      >
        <Box>
          <ActionButton
            size="small"
            label={tokens.automator.orders.dialogs.update_order_note.note}
            onClick={() => openUpdateOrderNoteDialog({ orderId: orderId })}
            variant="text"
            color="primary"
            icon={<CommentIcon />}
          />
        </Box>

        <Box>
          <ActionButton
            size="small"
            icon={<Toll />}
            label={tokens.automator.reimbursements.create_reimbursement_request}
            onClick={() =>
              openCreateEmailThreadDialog({
                orderId: orderId,
                phase: resolutionCase.messageThread.phase,
                aggregateId: null,
                body: null,
                messageTemplateId: null,
                recipientType: RecipientType.BOL,
                messageTemplateType: MessageTemplateType.REIMBURSEMENT_REQUEST,
              })
            }
            variant="text"
            color="primary"
          />
        </Box>
      </Stack>

      <Stack>
        <ActionButton
          icon={<ContentPasteSearchIcon />}
          label={tokens.automator.resolutions.dialogs.send_email.investigation_request_email}
          variant="text"
          onClick={() =>
            openCreateEmailThreadDialog({
              orderId: orderId,
              phase: resolutionCase.messageThread.phase,
              aggregateId: null,
              body: null,
              messageTemplateId: null,
              recipientType: RecipientType.BOL,
              messageTemplateType: MessageTemplateType.INVESTIGATION_REQUEST,
            })
          }
        />

        {resolutionCase.pickSession && (
          <NavigateToPickSessionButton pickSession={resolutionCase.pickSession} />
        )}
      </Stack>

      <Stack
        direction="column"
        alignItems="top"
        gap={0.25}
      >
        <GotoBolCaseActionButton
          bolCaseNumber={resolutionCase.bolCaseNumber}
          bolOrderId={bolOrderId}
          bolThreadOrderItemId={resolutionCase.bolThreadOrderItemId}
        />

        <ReturnResolutionCasePopoverButton orderId={orderId} />
      </Stack>
    </Stack>
  );
};

export default OrderResolutionCaseActions;
