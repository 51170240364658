import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchAccount } from '../../../api/automator/account/useFetchAccount';
import { SettingsForm } from './SettingsForm';
import { useFetchPickSessionSettings } from '../../../api/automator/pickSessions/useFetchPickSessionSettings';
import { TableLoading } from '../../../components/TableLoading';

const SettingsPage = () => {
  const { data: account, isLoading: isLoadingAccount } = useFetchAccount();

  const { data: pickSessionSettings, isLoading: isLoadingPickSessionSettings } =
    useFetchPickSessionSettings();

  if (isLoadingAccount || isLoadingPickSessionSettings) {
    return <TableLoading message={'...'} />;
  }

  if (!account || !pickSessionSettings) {
    return null;
  }

  return (
    <AccountPage title={tokens.automator.settings.settings}>
      <SettingsForm
        pickSessionSettings={pickSessionSettings}
        settings={account.settings}
      />
    </AccountPage>
  );
};

export default SettingsPage;
