import { RESTOCK_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';

export const useDeleteRestockItem = (restockItemId: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCK_ITEMS + `/${restockItemId}/delete`);

  return useAuthorizedPost<Restock, DeleteRestockItemData>(url, [
    InventoryItemsQueryKey(),
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    InventoryItemRestockItemsQueryKey(),
    InventoryStocksQueryKey(),
    InventoryItemStockAdjustmentsQueryKey(),
    InventoryItemInsightQueryKey(),
    InventoryItemSuppliersQueryKey(),
  ]);
};

export interface DeleteRestockItemData {
  also_remove_inventory_stock: boolean;
}
