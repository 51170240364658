import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import * as React from 'react';
import { SalesVariantInfo } from './SalesVariantInfo';
import RecommendedRestockReport from '../../../../domain/automator/stock/RecommendedRestockReport';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { StockPeriodDropdown, StockPeriodOption } from './StockPeriodDropdown';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';
import { Box } from '@mui/system';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import { InventoryStockType } from '../../../../domain/automator/stock/InventoryStockType';

interface RecommendedRestockReportTableRowProps {
  recommendedRestockReport: RecommendedRestockReport;
  stockPeriodOption: StockPeriodOption | null;
  onStockPeriodChange: (reportId: number, option: StockPeriodOption | null) => void;
}

export const RecommendedRestockReportTableRow = ({
  recommendedRestockReport,
  stockPeriodOption,
  onStockPeriodChange,
}: RecommendedRestockReportTableRowProps) => {
  const { t } = useTranslation();
  const openUpdateInventoryStockDialog = useOpenDialog(DialogId.UPDATE_INVENTORY_STOCK);

  // Calculate units, total cost, and total volume dynamically
  const units =
    recommendedRestockReport.averageSales30d && stockPeriodOption
      ? recommendedRestockReport.averageSales30d * (stockPeriodOption?.days || 0)
      : null;

  const totalCost =
    units && recommendedRestockReport.latestInventoryStock.stockValues.unitPriceInCents
      ? units * recommendedRestockReport.latestInventoryStock.stockValues.unitPriceInCents
      : null;

  const totalVolume =
    units && recommendedRestockReport.latestInventoryStock.stockValues.totalCubicMeters
      ? units * recommendedRestockReport.latestInventoryStock.stockValues.totalCubicMeters
      : null;

  return (
    <TableRow style={{ cursor: 'pointer' }}>
      <SlimTableCell>
        <SalesVariantInfo
          salesVariant={recommendedRestockReport.salesVariants[0]}
          hasSiblingVariant={recommendedRestockReport.salesVariants.length > 1}
          date={null}
        />
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="column"
          gap={1}
        >
          <Stack
            direction="row"
            gap={0.5}
          >
            <ScheduleIcon color="warning" />
            <Typography>
              {getShortendAlphabeticFormatFromDate(
                new Date(recommendedRestockReport.recommendedRestockDate!),
                false,
                true
              )}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            gap={0.5}
          >
            <EventBusyIcon color="primary" />
            <Typography>
              {getShortendAlphabeticFormatFromDate(
                new Date(recommendedRestockReport.expectedDepletionDate!),
                false,
                true
              )}
            </Typography>
          </Stack>
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <SignalCellularAltIcon color="warning" />
          <StockPeriodDropdown
            selected={stockPeriodOption}
            onSelect={(option) => onStockPeriodChange(recommendedRestockReport.id, option)}
            averageSales={recommendedRestockReport.averageSales30d}
          />
        </Stack>

        <Stack
          direction="row"
          gap={0.5}
        >
          <HouseSidingIcon color="info" />
          <Typography>
            {recommendedRestockReport.leadTime.manufacturingDurationInDays +
              recommendedRestockReport.leadTime.transportDurationInDays +
              ' ' +
              t(tokens.automator.stock.settings.supply_chain_phases.days)}
          </Typography>
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          gap={1}
        >
          {recommendedRestockReport.latestInventoryStock.type === InventoryStockType.STOCKTAKE && (
            <ActionIcon
              icon={<Edit02Icon />}
              onClick={() =>
                openUpdateInventoryStockDialog({
                  inventoryStock: recommendedRestockReport.latestInventoryStock,
                })
              }
              tooltip={t(tokens.automator.stock.update_restock_item.update_restock_item)}
            />
          )}

          <Typography width={100}>{t(tokens.automator.stock.restock_dialog.unit_price)}</Typography>
          <Typography>
            {recommendedRestockReport.latestInventoryStock.stockValues.unitPriceInCents
              ? '€' +
                convertCentsToEuros(
                  recommendedRestockReport.latestInventoryStock.stockValues.unitPriceInCents
                )
              : '-'}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          gap={1}
        >
          {recommendedRestockReport.latestInventoryStock.type === InventoryStockType.STOCKTAKE && (
            <ActionIcon
              icon={<Edit02Icon />}
              onClick={() =>
                openUpdateInventoryStockDialog({
                  inventoryStock: recommendedRestockReport.latestInventoryStock,
                })
              }
              tooltip={t(tokens.automator.stock.update_restock_item.update_restock_item)}
            />
          )}

          <Typography width={100}>{t(tokens.automator.stock.restock_dialog.volume)}</Typography>
          <Typography>
            {recommendedRestockReport.latestInventoryStock.stockValues.totalCubicMeters
              ? convertCentsToEuros(
                  recommendedRestockReport.latestInventoryStock.stockValues.totalCubicMeters
                ) + 'm3'
              : '-'}
          </Typography>
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Box>
          {totalCost && (
            <Typography sx={{ fontStyle: 'italic' }}>€{convertCentsToEuros(totalCost)}</Typography>
          )}
        </Box>
      </SlimTableCell>

      <SlimTableCell>
        <Box>
          {totalVolume && (
            <Typography sx={{ fontStyle: 'italic' }}>
              {roundDownTo1Decimal(totalVolume)} m3
            </Typography>
          )}
        </Box>
      </SlimTableCell>
    </TableRow>
  );
};
