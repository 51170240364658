import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { PickSessionTableRow } from './PickSessionTableRow';
import { Fragment, useRef } from 'react';
import { Collapse } from '@mui/material';
import PickOrdersListTable from './PickOrdersListTable';
import { tokens } from '../../../../locales/translationTokens';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useScrollIntoView } from '../../../../api/useScrollIntoView';
import { usePaths } from '../../../../routes/usePaths';
import { useNavigate } from 'react-router-dom';
import { TableRowLoading } from '../../../../components/TableRowLoading';
import { useFetchPickSessions } from '../../../../api/automator/pickSessions/useFetchPickSessions';

interface PickSessionTableRowsProps {
  date: string;
  selectedPickSessionId?: number;
}

export const PickSessionTableRows = ({
  date,
  selectedPickSessionId,
}: PickSessionTableRowsProps) => {
  const { t } = useTranslation();

  const { data: pickSessions, isLoading } = useFetchPickSessions(date);

  const pickSessionRefs = useRef<Map<number, HTMLTableRowElement | null>>(new Map());

  useScrollIntoView(selectedPickSessionId || null, pickSessionRefs.current, [
    selectedPickSessionId,
    pickSessionRefs,
    pickSessions,
  ]);

  const paths = usePaths();
  const navigate = useNavigate();

  if (isLoading || !pickSessions) {
    return <TableRowLoading message={'Loading...'} />;
  }

  if (pickSessions?.pickSessions.length === 0) {
    return <TableEmpty message={t(tokens.automator.pick_sessions.no_items)} />;
  }

  const onPickSessionSelect = (pickSessionId: number) => {
    if (selectedPickSessionId == pickSessionId) {
      navigate(paths.automator.pick_sessions);
    } else {
      navigate(paths.automator.pick_sessions + '/' + pickSessionId);
    }
  };

  const sortedPickSessions = [...pickSessions!.pickSessions].sort(
    (a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime()
  );

  return (
    <>
      {sortedPickSessions.map((pickSession) => (
        <Fragment key={pickSession.id}>
          <PickSessionTableRow
            ref={(el) => pickSessionRefs.current.set(pickSession.id, el)}
            onClick={onPickSessionSelect}
            pickSession={pickSession}
            key={pickSession.id}
          />
          <TableRow key={`collapse-${pickSession.id}`}>
            <TableCell
              style={{ padding: 0 }}
              colSpan={11}
            >
              <Collapse
                in={selectedPickSessionId == pickSession.id}
                unmountOnExit
              >
                <PickOrdersListTable pickSessionId={pickSession.id} />
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      ))}
    </>
  );
};
