import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import SalesVariant from '../../../../domain/automator/stock/SalesVariant';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { debounce, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useUpdateSalesVariant } from '../../../../api/automator/stock/useUpdateSalesVariant';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { InfoCircle } from '@untitled-ui/icons-react';
import { SalesVariantInfo } from './SalesVariantInfo';

interface SalesVariantTableRowProps {
  inventoryItem: InventoryItem;
  salesVariant: SalesVariant;
  hasSiblingVariant: boolean;
  canRemove: boolean;
}

export const SalesVariantTableRow = ({
  salesVariant,
  canRemove,
  inventoryItem,
  hasSiblingVariant,
}: SalesVariantTableRowProps) => {
  const [salesUnit, setSalesUnit] = useState<number | null>(salesVariant.salesUnit);

  const { mutate: doUpdateSalesVariant } = useUpdateSalesVariant(salesVariant.id);

  const openRemoveSalesVariantDialog = useOpenDialog(
    DialogId.REMOVE_SALES_VARIANT_FROM_INVENTORY_ITEM
  );

  const { t } = useTranslation();

  const doUpdateSalesUnit = (newSalesUnit: number) => {
    doUpdateSalesVariant(
      { sales_unit: newSalesUnit },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.sales_variant_table.sales_unit_updated));
        },
      }
    );
  };

  const debounceSetSalesUnit = useCallback(
    debounce((newSalesUnit: number | null) => {
      if (newSalesUnit == null) {
        return;
      }

      if (newSalesUnit && newSalesUnit != salesVariant.salesUnit && newSalesUnit >= 0.1) {
        doUpdateSalesUnit(newSalesUnit);
      }
    }, 500),
    []
  );

  return (
    <TableRow>
      <TableCell>
        <SalesVariantInfo
          salesVariant={salesVariant}
          hasSiblingVariant={hasSiblingVariant}
          date={null}
        />
      </TableCell>

      <TableCell>
        <Tooltip
          enterDelay={200}
          enterNextDelay={200}
          title={t(tokens.automator.stock.sales_variant_table.sales_unit_tooltip)}
        >
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
          >
            <Typography
              fontSize={18}
              fontWeight="bold"
            >
              {t(tokens.automator.stock.sales_variant_table.sales_unit_prefix)}
            </Typography>

            <Stack
              direction="row"
              gap={1}
              alignItems="center"
            >
              <TextField
                style={{ width: '85px' }}
                value={salesUnit}
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value == null || Number(value) > 0 || value == '') {
                    const normValue = value ? Number(value) : null;
                    setSalesUnit(normValue);
                    debounceSetSalesUnit(normValue);
                  }
                }}
                onBlur={() => debounceSetSalesUnit(salesUnit)}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                size={'small'}
              />

              <Typography
                fontSize={18}
                fontWeight="bold"
              >
                {t(tokens.automator.stock.sales_variant_table.sales_unit_postfix)}
              </Typography>
            </Stack>

            <InfoCircle color={'info'} />
          </Stack>
        </Tooltip>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={1}
        >
          {canRemove && (
            <ActionButton
              label={t(tokens.automator.stock.remove_sales_variant_dialog.remove_sales_variant)}
              onClick={() =>
                openRemoveSalesVariantDialog({
                  salesVariant: salesVariant,
                  inventoryItem: inventoryItem,
                })
              }
            />
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
