import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionDossierProductThumbnail } from './ResolutionDossierProductThumbnail';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';
import { FlagIcon } from '../../orders/components/FlagIcon';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';

interface ResolutionDossierCardProps {
  resolutionDossier: ResolutionDossier;
}

export const ResolutionDossierCard = ({ resolutionDossier }: ResolutionDossierCardProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        gap={1}
      >
        <ResolutionDossierProductThumbnail resolutionDossier={resolutionDossier} />
        {resolutionDossier.order && (
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <Stack direction={'column'}>
              <Stack
                direction="row"
                gap={0.4}
                alignItems={'center'}
              >
                <FlagIcon countryCode={resolutionDossier.order.shipmentDetails.countryCode} />
                <OrderRecipient
                  shipmentDetails={resolutionDossier.order.shipmentDetails}
                  doubleOrderBolOrderIds={resolutionDossier.order.doublePlacedBolOrderIds}
                  isDoublePlaceOrdersACancelled={
                    resolutionDossier.order.doublePlaceOrdersAreCancelled || false
                  }
                  repeatCustomerBolOrderIds={resolutionDossier.order.repeatCustomerBolOrderIds}
                />
              </Stack>

              <Stack
                direction="row"
                gap={0.5}
                alignItems={'center'}
              >
                <RetailerLooksIcon retailerId={resolutionDossier.order.retailerId} />
                <Typography variant={'body2'}>{resolutionDossier.order.bolOrderId}</Typography>
                <DistributionPartyChip
                  variant={'outlined'}
                  distributionParty={resolutionDossier.order.distributionParty}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      {resolutionDossier.order && (
        <SpeedDialActionIcon
          orderId={resolutionDossier.order.id}
          hasNote={resolutionDossier.order.hasNote}
        />
      )}
    </Stack>
  );
};
