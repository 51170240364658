import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LeadTime from '../../../../domain/automator/stock/LeadTime';
import DepletionSignal from './DepletionSignal';
import { Box } from '@mui/system';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { getShortenedAlphabeticFormatFromDate } from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { Tooltip } from '@mui/material';

interface DepletionPeriodChipProps {
  daysTillDepletion?: number;
  showDays?: boolean;
  leadTime: LeadTime;
}

export const DepletionPeriodChip = ({
  daysTillDepletion,
  leadTime,
  showDays = true,
}: DepletionPeriodChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (daysTillDepletion?: number) => {
    if (daysTillDepletion == undefined) {
      return tokens.automator.stock.depletion_period.unknown;
    } else if (daysTillDepletion < 1) {
      return tokens.automator.stock.depletion_period.depleted;
    } else if (daysTillDepletion <= 7 * 2) {
      return tokens.automator.stock.depletion_period.weeks_0_2;
    } else if (daysTillDepletion <= 7 * 4) {
      return tokens.automator.stock.depletion_period.weeks_2_4;
    } else if (daysTillDepletion <= 7 * 6) {
      return tokens.automator.stock.depletion_period.weeks_4_6;
    } else if (daysTillDepletion <= 7 * 8) {
      return tokens.automator.stock.depletion_period.weeks_6_8;
    } else if (daysTillDepletion <= 7 * 12) {
      return tokens.automator.stock.depletion_period.weeks_8_12;
    } else if (daysTillDepletion <= 7 * 16) {
      return tokens.automator.stock.depletion_period.weeks_12_16;
    } else if (daysTillDepletion <= 7 * 20) {
      return tokens.automator.stock.depletion_period.weeks_16_20;
    } else if (daysTillDepletion <= 7 * 24) {
      return tokens.automator.stock.depletion_period.weeks_20_24;
    } else if (daysTillDepletion <= 7 * 52) {
      return tokens.automator.stock.depletion_period.weeks_24_52;
    } else {
      return tokens.automator.stock.depletion_period.one_year_plus;
    }
  };

  const leadTimeDays = leadTime.transportDurationInDays + leadTime.manufacturingDurationInDays;

  const today = new Date();

  const depletionDate = daysTillDepletion
    ? new Date(today.setDate(today.getDate() + daysTillDepletion))
    : null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <DepletionSignal
        leadTimeDays={leadTimeDays}
        daysTillDepletion={daysTillDepletion}
      />

      <Box
        style={{
          alignItems: 'left',
          width: 205,
          height: 60,
          backgroundColor: 'white',
          color: 'black',
          borderRadius: 20,
          border: '1px solid #E0E0E0',
          padding: 5,
          paddingLeft: 10,
        }}
      >
        <Stack
          alignItems={'left'}
          direction="column"
        >
          <Stack
            direction="row"
            gap={1.25}
          >
            <BackupTableIcon />
            <Typography>{t(determineLabel(daysTillDepletion))}</Typography>
          </Stack>

          <Tooltip
            title={t(tokens.automator.stock.product_stock_summaries_table.depletion_date_tooltip)}
          >
            <Stack
              direction="row"
              gap={1}
            >
              <DoNotDisturbAltIcon />
              {daysTillDepletion && showDays && daysTillDepletion > 0 ? (
                <Typography>
                  {getShortenedAlphabeticFormatFromDate(depletionDate!, false, true)}
                </Typography>
              ) : (
                '-'
              )}
            </Stack>
          </Tooltip>
        </Stack>
      </Box>
    </Stack>
  );
};
