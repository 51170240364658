import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../locales/translationTokens';
import { Scrollbar } from '../../../../devias/src/components/scrollbar';
import { TableLoading } from '../../../components/TableLoading';
import { useFetchStampCodesReport } from '../../../api/automator/admin/useFetchStampCodesReport';
import { StampCodesAccountReportRow } from './StampCodesAccountReportRow';
import { useFetchAdminAccounts } from '../../../api/automator/admin/useFetchAdminAccounts';
import Stack from '@mui/material/Stack';

export const AdminStampCodesReportTable = () => {
  const { data: stampCodesReport, isLoading: isLoadingReports } = useFetchStampCodesReport();

  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAdminAccounts();

  const { t } = useTranslation();

  if (isLoadingReports || isLoadingAccounts || !accounts) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="column">
        <Stack
          direction="row"
          gap={2}
        >
          <div>Beschikbaar</div>
          <div>{stampCodesReport!.availableAmount}</div>
        </Stack>

        <Stack
          direction="row"
          gap={2}
        >
          <div>depletion7d</div>
          <div>{stampCodesReport!.depletion7d}</div>
        </Stack>
      </Stack>

      <Scrollbar>
        {stampCodesReport?.accountReports.length != 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account ID</TableCell>
                <TableCell align="right">E-mailadres</TableCell>
                <TableCell align="right">{'Week ' + stampCodesReport!.weeks[0]}</TableCell>
                <TableCell align="right">{'Week ' + stampCodesReport!.weeks[1]}</TableCell>
                <TableCell align="right">{'Week ' + stampCodesReport!.weeks[2]}</TableCell>
                <TableCell align="right">{'Week ' + stampCodesReport!.weeks[3]}</TableCell>
                <TableCell align="right">{'Week ' + stampCodesReport!.weeks[4]}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {stampCodesReport!.accountReports!.map((accountReport) => {
                return (
                  <StampCodesAccountReportRow
                    key={accountReport.accountId}
                    report={accountReport}
                    account={accounts!.find((it) => it.id == accountReport.accountId)!}
                    weeks={stampCodesReport!.weeks}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
      </Scrollbar>
    </Box>
  );
};
