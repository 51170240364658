import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import { useFetchPerformanceProducts } from '../../../../../api/pd/products/useFetchPerformanceProducts';
import { tokens } from '../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { TableLoading } from '../../../../../components/TableLoading';
import { TableEmpty } from '../../../../../components/TableEmpty';
import { Scrollbar } from '../../../../../../devias/src/components/scrollbar';
import PerformanceProduct from '../../../../../domain/pd/PerformanceProduct';
import Typography from '@mui/material/Typography';
import { usePaths } from '../../../../../routes/usePaths';
import { useRouter } from '../../../../../../devias/src/hooks/use-router';
import { ProductThumbnail } from '../../../../automator/orders/components/ProductThumbnail';

export const ProductsListTable = () => {
  const { data: products, isLoading: isLoadingProducts } = useFetchPerformanceProducts();

  const { t } = useTranslation();

  const paths = usePaths();
  const router = useRouter();

  const handleRowClick = (productId: number) => {
    router.push(paths.pd.products + '/' + productId + '/performances');
  };

  if (isLoadingProducts) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (products!.products.length == 0) {
    return <TableEmpty message={t(tokens.pd.products.no_items)} />;
  }

  const sortedData = products!.products.sort((a, b) =>
    a.offers[0].title.localeCompare(b.offers[0].title)
  );

  const groupedData = sortedData.reduce<Record<string, PerformanceProduct[]>>((acc, item) => {
    const firstLetter = item.offers[0].title.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          {Object.entries(groupedData).map(([letter, products]) => (
            <Box key={letter}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography variant="h6">{letter}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.id}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell onClick={() => handleRowClick(product.id)}>{product.ean}</TableCell>
                    <TableCell
                      onClick={() =>
                        product.productPageUrl && window.open(product.productPageUrl, '_blank')
                      }
                    >
                      <ProductThumbnail
                        thumbnailUrl={product.thumbnailUrl}
                        height={50}
                      />
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(product.id)}>
                      {product.offers[0].title}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          ))}
        </Table>
      </Scrollbar>
    </Box>
  );
};
