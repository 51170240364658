import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import Shipment from '../../../../domain/automator/shipments/Shipment';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { ShipmentRecipient } from '../../shipments/components/ShipmentReceipient';
import ShipmentStatusChip from '../../shipments/components/ShipmentEventStatus';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/shipments/ShipmentEventLoadingStatus';

interface EmailThreadShipmentSelectionProps {
  shipments: Shipment[];
  setAggregateId: (aggregateId: number) => void;
  selectedAggregateId: number | null;
}

export const EmailThreadShipmentSelection = ({
  shipments,
  setAggregateId,
  selectedAggregateId,
}: EmailThreadShipmentSelectionProps) => {
  return (
    <Stack
      direction="column"
      gap={1}
    >
      {shipments.map((shipment) => {
        return (
          <Stack
            direction="row"
            gap={2}
            alignItems={'center'}
            key={shipment.id}
          >
            {!selectedAggregateId && (
              <Radio
                checked={selectedAggregateId === shipment.id}
                onChange={() => setAggregateId(shipment.id)}
              />
            )}

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
            >
              <ShipmentStatusChip
                shipmentEventsLoadingStatus={ShipmentEventLoadingStatus.NOT_APPLICABLE}
                shipment={shipment}
              />

              <DistributionPartyChip distributionParty={shipment.order.distributionParty} />

              <ShipmentRecipient
                shipmentDetails={shipment.order.shipmentDetails}
                pickupPoint={shipment.pickupPoint}
              />
            </Stack>
          </Stack>
        );
      })}{' '}
    </Stack>
  );
};
